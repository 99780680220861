import React, { Component } from "react";
import { logout, isLogin } from "../../actions/AuthActions";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class Logout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLogin: isLogin(),
    };
  }

  handleLogout = () => {
    this.props.logout();
    this.setState({
      isLogin: false,
    });
  };

  render() {
    return (
      <div>
        {this.state.isLogin ? (
          <Link to="/" onClick={this.handleLogout}>
                          <i className="fas fa-sign-in-alt u-s-m-r-9"></i>Log
                          Out
                        </Link> 
        ) : (
          <Link to="/login">Go to sign in page</Link>
        )}
      </div>
    );
  }
}

export default connect(null, { logout })(Logout);
