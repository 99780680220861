import {trackOrderLink} from '../services/apiLink';
import {
  SET_TRACK_MY_ORDER,
  RESET_TRACK_MY_ORDER,
  DEFAULT_TRACK_MY_ORDER
} from './types';

export const trackMyOrder = (dispatch,order_key,email) => {
  dispatch(
    {
      type:RESET_TRACK_MY_ORDER
    });
  let result = fetch(trackOrderLink, {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({"order_key":order_key,"email":email})
    })
      .then((res) => {
          if (res.ok)
          {
            return res.json();
          }
          else {
            throw new Error('Something went wrong');
          }
      })
      .then(
        (result) => {
          dispatch({
            type:SET_TRACK_MY_ORDER,
            payload:result.data
          })
        }
      )
      .catch(
        (error) => {
              dispatch({
            type:SET_TRACK_MY_ORDER,
            payload:[]
          })
          }
        )
  };
  
