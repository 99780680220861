import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Logout from "../../components/Auth/Logout";

class TopHeader extends Component {
  constructor(props) {
    super(props);
  }
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    error: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
  };

  render() {
    let userDetails = JSON.parse(localStorage.getItem("profileData"));
    return (
      <React.Fragment>
        <div className="full-layer-outer-header">
          <div className="container clearfix">
            <nav>
              <ul className="primary-nav g-nav">
                <li>
                  <a href="tel:+111444989">
                    <i className="fas fa-phone u-c-brand u-s-m-r-9"></i>
                    Telephone:{this.props.phone}
                  </a>
                </li>
                <li>
                  <a href="mailto:contact@domain.com">
                    <i className="fas fa-envelope u-c-brand u-s-m-r-9"></i>
                    E-mail: {this.props.email}
                  </a>
                </li>
              </ul>
            </nav>
            {this.props.isAuthenticated ? (
              <nav>
                <ul className="secondary-nav g-nav">
                  <li>
                    <a>
                      {userDetails.organization} Account
                      <i className="fas fa-chevron-down u-s-m-l-9"></i>
                    </a>
                    <ul className="g-dropdown" style={{ width: "200px" }}>
                      <li>
                        <Link to="/user-profile">
                          <i className="fas fa-sign-in-alt u-s-m-r-9"></i>My
                          Profile
                        </Link>
                      </li>
                      <li>
                        <Link to="/my-cart">
                          <i className="fas fa-shopping-cart u-s-m-r-9"></i>My
                          Cart
                        </Link>
                      </li>
                      {/* <li>
                                    <a href="wishlist.html">
                                        <i className="far fa-heart u-s-m-r-9"></i>
                                        My Wishlist</a>
                                </li>
                                <li>
                                    <a href="checkout.html">
                                        <i className="far fa-check-circle u-s-m-r-9"></i>
                                        Checkout</a>
                                </li> */}

                      <li>
                        <Link to="/myorder">
                          <i className="fas fa-sign-in-alt u-s-m-r-9"></i>My
                          Order
                        </Link>
                      </li>
                      <li>
                        <div className="log-out">
                          <Logout />
                        </div>
                        {/* <Link to="/logout">
                          <i className="fas fa-sign-in-alt u-s-m-r-9"></i>Log
                          Out
                        </Link> */}
                      </li>
                    </ul>
                  </li>
                  {/*<li>
                            <a>ENG
                                <i className="fas fa-chevron-down u-s-m-l-9"></i>
                            </a>
                            <ul className="g-dropdown" style={{"width":"70px"}}>
                                <li>
                                    <a href="#" className="u-c-brand">ENG</a>
                                </li>
                                <li>
                                    <a href="#">ARB</a>
                                </li>
                            </ul>
                        </li>*/}
                </ul>
              </nav>
            ) : (
              <ul className="secondary-nav g-nav">
                <li>
                  <Link to="/sign-in">
                    <i className="fas fa-sign-in-alt u-s-m-r-9"></i>Login
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  // };
}

const mapStateToProps = (state) => ({
  test: state,
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
  phone: state.layout.owner_info.telephone,
  email: state.layout.owner_info.email,
});

export default connect(mapStateToProps)(TopHeader);
