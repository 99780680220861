import {orderPostLink} from '../services/apiLink';
import { Button, notification } from "antd";

import {RESET_CART,SET_PLACE_ORDER_LOADING,RESET_PLACE_ORDER_LOADING} from './types';

const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
  });
};

const postOrder=(dispatch,data, note, date)=>
{   

	let products=[];
	data.map((item,index)=>{products.push(
		{
            "id": item.product_id,
            "title": item.product_name,
            "quantity": item.quantity,
            "price": 0,
            "image": item.image,
            "note": ""
        }
		)})

	let body = {
        "products":products,
        "amount": 0,
        "prefer_date": date,
        "note": note
    };

    dispatch({type:SET_PLACE_ORDER_LOADING, payload:{}});
    
	let result = fetch(orderPostLink, {
      method: 'POST',
      headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer" + window.localStorage.getItem("token"),
        },
      body: JSON.stringify(body)
    })
      .then((res) => {
          if (res.ok)
          {
            return res.json();
          }
          else {
            throw new Error('Something went wrong');
          }
      })
      .then(
        (result) => { 
            const type = "success";
            const message = "Success";
            const description = "Order Placed Successfully!";
            openNotificationWithIcon(type, message, description);
            dispatch({type:RESET_PLACE_ORDER_LOADING, payload:{}});
            dispatch({type:RESET_CART, payload:{}});
        }
      )
      .catch(
        (error) => {
                const type = "error";
                const message = error.response.data.error;
                const description = "Failed to place order!";
                openNotificationWithIcon(type, message, description);
                dispatch({type:RESET_PLACE_ORDER_LOADING, payload:{}});
          }
        )
}

export default postOrder;