import {
	SET_TRACK_MY_ORDER,
	RESET_TRACK_MY_ORDER,
	DEFAULT_TRACK_MY_ORDER
} from '../actions/types';

let initialState ={
	order_status:0,
	order_data:[]
}

const trackMyOrderReducer = (state=initialState, action)=>{
	switch(action.type){
		case SET_TRACK_MY_ORDER:
			return {...state,order_status:2,order_data:action.payload}
		case RESET_TRACK_MY_ORDER:
			return {...state, order_status:1, order_data:[]}
		case DEFAULT_TRACK_MY_ORDER:
			return {...state,order_status:0, order_data:[]}
		default:
			return state;
	}
}

export default trackMyOrderReducer;

