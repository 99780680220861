import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Table, Divider, Tag, Modal, Spin, List, Avatar } from "antd";
import "antd/dist/antd.css";
import { fetchOrders, deleteOrder } from "../../actions/OrderAction";
import OrderListView from "../../components/Order/OrderListview";
class MyOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: false,
      orders: [],
      orderDetails: [],
      DetailId: "",
    };
  }
  componentWillMount() {
    const { fetchOrders } = this.props;
    fetchOrders();
  }

  showModal = (detail) => {
    // console.log(detail);
    this.setState({
      visible: true,
      orderDetails: detail,
    });
  };
  isModalVisible = () => {
    console.log("hello");
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };
  cancelOrder = (id) => {
    // console.log(id);
    this.setState({
      ...this.state,
      DetailId: id,
    });
    console.log(id, "testst");
    return new Promise((resolve, reject) => {
      this.props.deleteOrder(id, resolve, reject);
    })
      .then(() => {
        this.setState({
          ...this.state,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          ...this.state,
          loading: false,
        });
      });
  };

  render() {
    console.log(this.props);
    const { orders } = this.props;
    const orderData = this.props.orders;
    const { loadings } = this.state.loading;
    const { Column, ColumnGroup } = Table;
    const data = orders.map((order) => ({
      id: order.id,
      status: order.status,
      order_date: order.order_date,
      detail: order.product_details,
      order_key: order.order_key,
    }));

    let tableLoading = false;
    if (data.length == 0) {
      tableLoading = {
        spinning: false,
      };
    } else if (data.length > 0) {
      tableLoading = {
        spinning: false,
      };
    } else {
      tableLoading = {
        spinning: true,
      };
    }

    return (
      <>
        <div class="page-style-a">
          <div class="container">
            <div class="page-intro">
              <h2>My Orders</h2>
              <ul class="bread-crumb">
                <li class="has-separator">
                  <i class="ion ion-md-home"></i>
                  <a href="home.html">Home</a>
                </li>
                <li class="is-marked">
                  <a href="account.html">My Orders</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div style={{ padding: 20 }}>
          <Table dataSource={data} loading={tableLoading}>
            {/* <Column title="Product" dataIndex="firstName" key="firstName" /> */}
            <Column title="ORDER ID" dataIndex="order_key" key="order_key" />
            <Column
              title="Order Date"
              dataIndex="order_date"
              key="order_date"
            />
            <Column title="Status" dataIndex="status" key="status" />
            <Column
              title="Action"
              key="id"
              render={(text, record) => (
                <span>
                  {record.status == "PENDING" ? (
                    <Button
                      type="primary"
                      onClick={() => this.cancelOrder(record.id)}
                      danger
                    >
                      CANCEL
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      disabled="disabled"
                      onClick={() => this.cancelOrder(record.id)}
                      danger
                    >
                      CANCEL
                    </Button>
                  )}
                  <Divider type="vertical" />
                  <Button
                    type="primary"
                    onClick={() => this.showModal(record.detail)}
                    style={{ background: "#1a004a", borderColor: "yellow" }}
                  >
                    View Product
                  </Button>
                  <Modal
                    title="Product List"
                    visible={this.state.visible}
                    orderDetails={orderData}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                  >
                    <OrderListView dataFromParent={this.state} />
                  </Modal>
                </span>
              )}
            />
          </Table>
        </div>
      </>
    );
  }
}
MyOrder.propTypes = {
  fetchOrders: PropTypes.func.isRequired,
  orders: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  orders: state.orders.items,
  loading: state.orders.isLoading,
  deleteOrder: state.orders.isLoading,
  updateList: state.orders.item,
});
export default connect(mapStateToProps, { fetchOrders, deleteOrder })(MyOrder);
