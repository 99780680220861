import React from 'react';

import {
	SET_MAINSLIDER_DATA,
	SET_MAINSLIDER_ERROR,
	SET_SLIDER1_DATA,
	SET_SLIDER1_ERROR,
	SET_SLIDER2_DATA,
	SET_SLIDER2_ERROR,
	SET_SLIDER3_DATA,
	SET_SLIDER3_ERROR
} from '../actions/types';


let initialState ={
	mainslider_status:0,
	slider1_status:0,
	slider2_status:0,
	slider3_status:0,
	mainslider_data:[],
	slider1_data:[],
	slider2_data:[],
	slider3_data:[]
}

const homeReducer = (state=initialState, action)=>{
	switch(action.type){
		case SET_MAINSLIDER_DATA:
			return {
				...state,
				mainslider_status:1,
				mainslider_data:action.payload
			}
		case SET_MAINSLIDER_ERROR:
			return {
				...state,
				mainslider_status:-1,
				mainslider_data:[]
			}
		case SET_SLIDER1_DATA:
			return{
				...state,
				slider1_status:1,
				slider1_data:action.payload
			}
		case SET_SLIDER1_ERROR:
			return{
				...state,
				slider1_status:-1,
				slider1_data:[]
			}
		case SET_SLIDER2_DATA:
			return{
				...state,
				slider2_status:1,
				slider2_data:action.payload
			}
		case SET_SLIDER2_ERROR:
			return{
				...state,
				slider2_status:-1,
				slider2_data:[]
			}
		case SET_SLIDER3_DATA:
			return{
				...state,
				slider3_status:1,
				slider3_data:action.payload
			}
		case SET_SLIDER1_ERROR:
			return{
				...state,
				slider3_status:-1,
				slider3_data:[]
			}
		default:
			return state;
	}
}

export default homeReducer;