import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import ProductComponent from '../ProductComponent/ProductComponent';
import ProductCard from '../ProductCard/ProductCard';
import { Pagination, Spin, Alert,Space,Slider,Input  } from 'antd';

import {get_data} from '../../actions/allProductAction'

class All_Product_List extends Component{
    constructor(props) {
              super(props);
              this.page=1;
              this.state = {
                            sort_column: "product_name",
                            sort_order: "ASC",
                            start: 0,
                            limit: 10,
                            search: null,
                            product_category_id: new Array(),
                            filter_column: null,
                            filter_value: null,
                            view:"grid",
                            min_price:0,
                            max_price:100,
                            category_search:""
              };
            }

	componentDidMount()
		 {
		  this.props.get_data_local(this.state,1);
		 }

    refresh_data(payload={}, pagenation_reset)
    {   if(pagenation_reset===1)
        {
            this.page =1;
            payload = {...payload,start:0,limit:10}
        }
        this.setState({...payload});
        this.props.get_data_local({...this.state,...payload},pagenation_reset);
    }

    check_uncheck_category(category)
    {
        console.log(this.state.product_category_id);
        let state1 = this.state.product_category_id;
        let index;
        if(state1.length===0)
        {
            index = -1;
        }
        else{
            index = state1.findIndex(item => item.id === category.id);
        }
        if(index>=0)
        {
            state1.splice(index, 1);
        }
        else if(state1.length===0)
        {
            state1 = [category];
        }
        else{
            state1.push(category);
        }
        this.refresh_data({product_category_id:state1},1)
    }


	render()
	{
	return(
		<>
		<div className="page-style-a">
	        <div className="container">
	            <div className="page-intro">
	                <h2>All Products</h2>
	                <ul className="bread-crumb">
	                    <li className="has-separator">
	                        <i className="ion ion-md-home"></i>
	                        <Link to="/">Home</Link>
	                    </li>
	                    <li className="is-marked">
	                        <Link to="/all-products">All Products</Link>
	                    </li>
	                </ul>
	            </div>
	        </div>
    	</div>

    	<div className="col-md-8 col-sm-12 offset-md-3">
                    <div className="page-bar clearfix" style={{"margin":"10px"}}>
                        <div className="shop-settings">
                            <a id="list-anchor" className={this.state.view==='list'?'active':''} onClick={()=>this.setState({view:"list"})}>
                                <i className="fas fa-th-list"></i>
                            </a>
                            <a id="grid-anchor" className={this.state.view==='grid'?'active':''}  onClick={()=>this.setState({view:"grid"})}>
                                <i className="fas fa-th"></i>
                            </a>
                        </div>
                        <div className="toolbar-sorter">
                            <div className="select-box-wrapper">
                                <label className="sr-only" for="sort-by">Sort By</label>
                                <select className="select-box" id="sort-by">
                                    <option selected="selected" value="" onClick={()=>this.refresh_data({sort_column:'product_name',sort_order:'ASC'},1)}>Sort By: Name [A-Z]</option>
                                    <option value="" onClick={()=>this.refresh_data({sort_column:'product_name',sort_order:'DESC'},1)}>Sort By: Name [Z-A]</option>
                                </select>
                            </div>
                        </div>
                        <div className="toolbar-sorter input-group" style={{"width":"30%", "margin-left":"auto","margin-right":"auto"}}>
                              <input type="search" className="form-control rounded" placeholder="Search" aria-label="Search"
                                aria-describedby="search-addon" onChange={(evt)=>this.refresh_data({search:evt.target.value},1)}/>
                        </div>
                    </div>                 
        </div>

        <div className="container-fluid">
            <div className="row">
                <div className="col-md-2 offset-md-1 col-sm-12">
                    <div className="facet-filter-associates sticky-category">
                        <h3 className="title-name">Categories{this.state.product_category_id.length===0?'':`(${this.state.product_category_id.length})`}</h3>
                        <Input placeholder="Search" style={{"width":"80%", "border":"1px solid white","border-bottom":"1px solid #DDDDDD"}} allowClear onChange={(e)=>this.setState({"category_search":e.target.value})}/>
                        <form className="facet-form" action="#" method="post">
                            <div className="grooverScrollDiv" 
                                style={{"position": "relative"}}>
                                <div class="associate-wrapper" style={{"overflow":"scroll"}}>
                                    {
                                        this.props.category.map(
                                            (item,index)=>{
                                                if((item.name.toLowerCase()).startsWith(this.state.category_search.toLowerCase()))
                                                {
                                                    return (
                                                    <>
                                                    <input type="checkbox" class="check-box" id={`cbs-${index}`} onChange={()=>this.check_uncheck_category(item)}
                                                    checked={this.state.product_category_id.length>0 && (this.state.product_category_id.findIndex(x => x.id === item.id)>=0)}
                                                    />
                                                    <label class="label-text" for={`cbs-${index}`}>{item.name}
                                                    </label>
                                                    </>
                                                    )
                                                }
                                            }
                                            )
                                    }
                                    
                                </div>
                            </div>
                        </form>
                        <h3 className="title-name">Price Filter(${this.state.min_price}-${this.state.max_price})</h3>
                        <Slider
                          range
                          step={1}
                          defaultValue={[0, 100]}
                          onChange={(value)=>this.refresh_data({"min_price":value[0],"max_price":value[1]},1)}
                        />
                    </div>
                </div>
                <div className="col-md-8 col-sm-12">
                {
                                (this.props.status===1&&this.state.search)?(
                                    <div>
                                        <h5>{this.props.total_item===0?'No result found for':'Showing results for'}: {this.state.search}</h5>
                                    </div>
                                    ):(<></>)

                } 
                {
                                this.props.pagenation_status===1?(
                                    <div style={{"display":"block"}}>
                                                <Pagination
                                                      total={this.props.total_item}
                                                      showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                                      defaultPageSize={10}
                                                      defaultPage={1}
                                                      current = {this.page}
                                                      onChange={(page, pageSize)=>{this.page=page; this.refresh_data({start:((page-1)*pageSize),limit:pageSize},0)}}
                                                    />
                                    </div> 
                                    ):(<></>)
                            }
                <br />
                {
                    this.state.view==='list'?
                    (
                        <>{
                            this.props.status===1?(
                            this.props.data.map((item,index) => {return (
                                <ProductComponent image={item.image} name={item.product_name} category={item.product_category_name} id={item.product_id} data={item}/>
                                )})):(<Spin size="large" style={{position: "absolute", left: "50vw",transform: "translate(-50%, 0%)"}} tip="Loading...Please Wait."/>)
                        }</>
                        ):(
                        <div className = "row product-container grid-style">
                        {
                            this.props.status===1?(
                            this.props.data.map((item,index) => {return (
                                <ProductCard image={item.image} name={item.product_name} category={item.product_category_name} id={item.product_id} data={item}/>
                                )})):(<Spin size="large" style={{position: "absolute", left: "50%",transform: "translate(-50%, 0%)"}} tip="Loading...Please Wait."/>)
                        }
                        </div>
                        )

                }
                
                </div>
            </div>
        </div>
    	</>

		)
	}

}

const mapStateToProps=(state)=>{
  return{
    data:state.all_product.all_product_data,
    status:state.all_product.all_product_status,
    total_item: state.all_product.total_item,
    pagenation_status:state.all_product.pagenation_status,
    category:state.layout.navbar_list
  }
}

const mapDispatchToProps=(dispatch)=>{
return{
get_data_local:(local_state, pagenation_reset)=>get_data(dispatch,local_state, pagenation_reset)
}
}

export default connect(mapStateToProps,mapDispatchToProps)(All_Product_List);