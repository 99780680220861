import 
{
	SET_PRODUCT_DETAIL,
	RESET_PRODUCT_DETAIL, 
	SET_PRODUCT_DETAIL_ERROR,
  RESET_SIMILAR_PRODUCT_DETAIL
} from './types';

import {productcompletelist_status,filtered_product_list} from './productCompleteListAction';

import {allproductLink} from '../services/apiLink';
import {get_similar_product_data} from './similarProductAction';

export const get_data=(dispatch,name)=>
{
  dispatch({type:RESET_PRODUCT_DETAIL})
  dispatch({type:RESET_SIMILAR_PRODUCT_DETAIL})

  let local_state = {
                            filter_column: 'product_name',
                            filter_value: name,
                            start:0,
                            limit:1
              }


try{

      if(productcompletelist_status()===1)
      {
        let result = filtered_product_list(local_state);
        if(result.data.length>0)
        {
          dispatch({
                    type: SET_PRODUCT_DETAIL,
                    payload:result.data[0]
                  }
                  )
          get_similar_product_data(dispatch,result.data[0].product_category_id);
        }
        else
        {
          dispatch({type: SET_PRODUCT_DETAIL_ERROR}
                  )
        }
        return;
      }

    }
catch(err)
    {

    }

  let result = fetch(allproductLink, {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(local_state)
    })
      .then((res) => {
          if (res.ok)
          {
            return res.json();
          }
          else {
            throw new Error('Something went wrong');
          }
      })
      .then(
        (result) => {
        	if(result.data.length>0){
        		dispatch({
                type: SET_PRODUCT_DETAIL,
                payload:result.data[0]
              }
              );
            get_similar_product_data(dispatch,result.data[0].product_category_id);
        	}
        	else{
        		dispatch({
                type: SET_PRODUCT_DETAIL_ERROR
              }
              )
        	}
          
        }
      )
      .catch(
        (error) => {
              dispatch({
                type: SET_PRODUCT_DETAIL_ERROR
              }
              )
          }
        )
}

