import {
  FETCH_ORDER_LIST,
  ORDER_REQUEST,
  ORDER_CANCEL,
  ORDER_CANCEL_FAIL,
} from "./types";
import { orderListLink, orderCancelLink } from "../services/apiLink";
import axios from "axios";
import { Button, notification } from "antd";

const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const fetchOrders = () => (dispatch) => {
  fetch(orderListLink, {
    method: "GET",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer" + window.localStorage.getItem("token"),
    },
  })
    .then((res) => res.json())
    .then((orders) =>
      dispatch({
        type: FETCH_ORDER_LIST,
        payload: orders.data,
      })
    );
};

export const deleteOrder =
  (id, history, resolve, reject) =>
  async (dispatch) => {
    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer" + window.localStorage.getItem("token"),
      },
    };
    console.log(id, "Check");
    // Request body
    const body = {
      order_id: id,
    };
    try {
      // dispatch({
      //   type: ORDER_REQUEST,
      // });
      const test = await axios
        .post(orderCancelLink, body, config)
        .then((res) => {
          console.log(res.data, "hello");
          if (res) {
            dispatch({
              type: ORDER_CANCEL,
              payload: res.data,
            });
            dispatch(fetchOrders());
            
            const type = "success";
            const message = "Success";
            const description = "Sucessfully Order has been Cancelled.";
            openNotificationWithIcon(type, message, description);
            //history.push("/");
            resolve(res);
          } else {
            //console.log(JSON.stringify(res,'test'));
            //dispatch(returnErrors(res.data.Message, res.data.Message, 'LOGIN_FAIL'));
            dispatch({});
            resolve(res);
          }
        });
    } catch (err) {
      if (err.response) {
        const type = "error";
        const message = err.response.data.error;
        const description = "Failed Logged in.";
        openNotificationWithIcon(type, message, description);
        dispatch({
          type: ORDER_CANCEL_FAIL,
        });
        console.log(err.response.data.error);
        // client received an error response (5xx, 4xx)
      } else if (err.request) {
        //console.log(err.request)
        // client never received a response, or request never left
      } else {
        // anything else
      }
    }
  };
