import axios from "axios";
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  LOGIN_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  FORGOT_PASSWORD_SEND_LINK_SUCCESS,
  FORGOT_PASSWORD_SEND_LINK_FAIL,
  FORGOT_PASSWORD_LINK_STATUS,
  REGISTER_REQUEST,
  FETCH_PROFILE_DATA
} from "./types";
import {
  loginUserLink,
  changePasswordLink,
  signUp,
  forgotPassword,
  userUpdate,
  userProfile
} from "../services/apiLink";
import { Button, notification } from "antd";

const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
  });
};

export const login =
  ({ email, password }, history, resolve, reject) =>
  async (dispatch) => {
    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    console.log(email, password, "Auth Check");
    // Request body
    const body = {
      email: email,
      password: password,
    };
    try {
      dispatch({
        type: LOGIN_REQUEST,
      });
      const test = await axios.post(loginUserLink, body, config).then((res) => {
        console.log(res.data, "hello");
        if (res.data) {
          //console.log(res.data.token);
          const token = res.data.token;
          const profileData = JSON.stringify(res.data.user);
          const currentTime = new Date().getTime();
          const expiryTime = currentTime + 19 * 60 * 1000;
          localStorage.setItem("expiryTime", expiryTime);
          window.localStorage.setItem("token", token);
          window.localStorage.setItem("profileData", profileData);
          dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data.user,
          });
          const type = "success";
          const message = "Success";
          const description = "Sucessfully Logged in.";
          openNotificationWithIcon(type, message, description);
          history.push("/");
          resolve(res);
        } else {
          //console.log(JSON.stringify(res,'test'));
          //dispatch(returnErrors(res.data.Message, res.data.Message, 'LOGIN_FAIL'));
          dispatch({
            type: LOGIN_FAIL,
          });
          resolve(res);
        }
      });
    } catch (err) {
      if (err.response) {
        const type = "error";
        const message = err.response.data.error;
        const description = "Failed Logged in.";
        openNotificationWithIcon(type, message, description);
        dispatch({
          type: LOGIN_FAIL,
        });
        console.log(err.response.data.error);
        // client received an error response (5xx, 4xx)
      } else if (err.request) {
        //console.log(err.request)
        // client never received a response, or request never left
      } else {
        // anything else
      }
    }
  };

export const isLogin = () => {
  if (window.localStorage.getItem("token")) {
    return true;
  }

  return false;
};

const sessionOut = () => {
  window.localStorage.clear();
};

export const logout = () => (dispatch) => {
  sessionOut();
  dispatch({
    type: LOGOUT_SUCCESS,
  });
  const type = "success";
  const message = "Success";
  const description = "Sucessfully Logout.";
  openNotificationWithIcon(type, message, description);
};

export const changePassword = ({
  accessToken,
  passwordDetails,
  onSuccess,
  onFailure,
}) => {
  return (dispatch) => {
    console.log(JSON.stringify(passwordDetails));
    return axios
      .post(changePasswordLink, passwordDetails, {
        headers: {
          Authorization: "Bearer" + window.localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res);
        onSuccess();
      })
      .catch((error) => {
        onFailure(error.response.data.error);
      });
  };
};

export const register = ({ signUpDetails, onSuccess, onFailure }) => {
  return (dispatch) => {
    dispatch({
      type: REGISTER_REQUEST,
    });
    //console.log(JSON.stringify(signUpDetails));
    return axios
      .post(signUp, signUpDetails, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res);
        dispatch({
          type: REGISTER_SUCCESS,
          payload: res.data,
        });

        onSuccess();
      })
      .catch((error) => {
        dispatch({
          type: REGISTER_FAIL,
        });
        //console.log(JSON.stringify(error));
        onFailure(JSON.stringify(error.response.data.errors));
      });
  };
};

export const passwordForgot = ({ email, onSuccess, onFailure }) => {
  return (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD_LINK_STATUS,
    });
    return axios
      .post(forgotPassword, {
        email: email,
      })
      .then((res) => {
        console.log(res.data.message.msg);
        dispatch({
          type: FORGOT_PASSWORD_SEND_LINK_SUCCESS,
          payload: {},
        });
        onSuccess({ msg: res.data.message.msg });
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
        onFailure(err.response.data.error);
      });
  };
};

export const updateUser = ({
  accessToken,
  updateDetails,
  onSuccess,
  onFailure,
}) => {
  return (dispatch) => {
    console.log(JSON.stringify(updateDetails));
    return axios
      .post(userUpdate, updateDetails, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        //dispatch(loginSuccessful(res.data));
        onSuccess();
      })
      .catch((error) => {
        onFailure(error.response.data);
        console.log(JSON.stringify(error.response));
      });
  };
};

export const fetchProfile = () => (dispatch) => {
  fetch(userProfile, {
    method: "GET",
    headers: {
      //Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + window.localStorage.getItem("token"),
    },
  })
    .then((res) => res.json())
    .then((res) =>
      dispatch({
        type: FETCH_PROFILE_DATA,
        payload: res.user,
      })
    );
};
