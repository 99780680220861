import React from "react";
import { NavLink } from "react-router-dom";
import android from "../../../assets/images/app/android_play_store.png";
import ios from "../../../assets/images/app/ios_app_store.png";
import {connect} from 'react-redux';

const FooterLinks = (props) => {
  return (
    <div className="footer-contaner">
      <div>
        <h5 style={{"color":"white"}}>CUSTOMER SERVICE</h5>
        <ul className="list-unstyled text-small">
          <li>
            <NavLink to="/faq">FAQs</NavLink>
          </li>
          <li>
            <NavLink to="/track-order">Track Order</NavLink>
          </li>
          <li>
            <NavLink to="/privacy-policy">Privacy Policy</NavLink>
          </li>
        </ul>
      </div>
      <div>
        <h5 style={{"color":"white"}}>COMPANY</h5>
        <ul className="list-unstyled text-small">
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <NavLink to="/about">About</NavLink>
          </li>
          <li>
            <NavLink to="/contact-us">Contact Us</NavLink>
          </li>
        </ul>
      </div>
      <div>
        <h5 style={{"color":"white"}}>Join Us</h5>
        <ul className="list-unstyled text-small">
          <li>
            <a href={`${props.owner_info.twitter}`}>Twitter</a>
          </li>
          <li>
            <a href={`${props.owner_info.facebook}`}>Facebook</a>
          </li>
          <li>
            <a href={`${props.owner_info.instagram}`}>Instagram</a>
          </li>
        </ul>
      </div>

      <div>
        <ul className="list-unstyled text-small">
          <li>
            <NavLink to="/"><img src={android} style={{"width":"130px","margin-bottom":"10px"}}/></NavLink>
          </li>
          <li>
            <NavLink to="/"><img src={ios} style={{"width":"130px","margin-bottom":"10px"}}/></NavLink>
          </li>
        </ul>
      </div>

    </div>
  );
};

const mapStateToProps=(state)=>{
  return {
    owner_info:state.layout.owner_info
  }
}

export default connect(mapStateToProps,null)(FooterLinks);
