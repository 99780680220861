import React, { useState, useEffect} from "react";
import axios from "axios";
import {connect} from "react-redux";
import {Link,useParams} from 'react-router-dom';

const NavBar = (props) => {
  const [count, setCount] = useState(0);
  const [arr, setarr] = useState(0);

  return (
    <React.Fragment>
        <div className="full-layer-bottom-header"  style={{"paddingBottom":"0px"}}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="d-lg-none col-12 p-2">
                        <div className={count==1?'v-menu':'v-menu v-close'}>
                            <span className="v-title" onClick={() => {setCount(1-count);setarr(count*arr)}}>
                                <i className="ion ion-md-menu"></i>
                            </span>
                            <span className="v-title">
                                <Link to='/' style={{"color":"white"}} >Home</Link>
                            </span>
                            <span className="v-title">
                                <Link to='/contact-us' style={{"color":"white"}} >Contact Us</Link>
                            </span>
                            <nav>
                                <div className="v-wrapper">
                                    <ul className="v-list animated fadeIn">
                                        <li className="js-backdrop">
                                            <Link>Shop By Category
                                            </Link>
                                            <button className="v-button" onClick={()=>(setarr(1-arr))}>{arr===1?'-':'+'}</button>
                                            <div className="v-drop-right" style={arr===1?{"width": "700px", "display":"block"}:{"width": "700px"}}>
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <ul >
                                                        {(props.navbar_list).map((item,index)=>{
                                                            return (
                                                            <li>
                                                               <Link onClick={()=>{setCount(0);setarr(0);}} to={"/category/"+String(item["name"]).toLowerCase()}><span style={{"fontSize":"12px"}}>{item["name"]}</span></Link>
                                                            </li>
                                                            )
                                                        })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="js-backdrop">
                                            <Link to='/featured-products'  onClick={()=>setCount(0)}>Featured Products
                                            </Link>
                                        </li>
                                        <li className="js-backdrop">
                                            <Link to='/daily-deals'  onClick={()=>setCount(0)}>Daily Deals
                                            </Link>
                                        </li>
                                        <li className="js-backdrop">
                                            <Link to='/all-products'  onClick={()=>setCount(0)}>All Products
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                    <div className="col-12" style={{"margin":"0 auto","textAlign":"center"}}>
                        <ul className="bottom-nav g-nav u-d-none-lg" style={{"margin":"0px"}}>
                        <li className="mega-position">
                                <Link to='/' style={{"color":"white"}}>Home
                                </Link>
                        </li>
                        <li className="mega-position">
                                <Link style={{"color":"white"}}>Shop By Category &nbsp;
                                    <i className="fas fa-angle-down"></i>
                                </Link>
                                <div className="mega-menu mega-3-colm">
                                    <ul>
                                    {(props.navbar_list).map((item,index)=>{
                                        if(index%4==0){
                                        return (
                                        <li>
                                           <Link to={"/category/"+String(item["name"]).toLowerCase()}>{item["name"]}</Link>
                                        </li>
                                        )}
                                    })}
                                    </ul>
                                    <ul>
                                    {(props.navbar_list).map((item,index)=>{
                                        if(index%4==1){
                                        return (
                                        <li>
                                           <Link to={"/category/"+String(item["name"]).toLowerCase()}>{item["name"]}</Link>
                                        </li>
                                        )}
                                    })}
                                    </ul>
                                    <ul>
                                    {(props.navbar_list).map((item,index)=>{
                                        if(index%4==2){
                                        return (
                                        <li>
                                           <Link to={"/category/"+String(item["name"]).toLowerCase()}>{item["name"]}</Link>
                                        </li>
                                        )}
                                    })}
                                    </ul>

                                    <ul>
                                    {(props.navbar_list).map((item,index)=>{
                                        if(index%4==3){
                                        return (
                                        <li>
                                           <Link to={"/category/"+String(item["name"]).toLowerCase()}>{item["name"]}</Link>
                                        </li>
                                        )}
                                    })}
                                    </ul>
                                </div>
                        </li>
                        <li className="mega-position">
                                <Link to='/featured-products' style={{"color":"white"}}>Featured Products
                                </Link>
                        </li>
                        <li className="mega-position">
                                <Link to='/daily-deals' style={{"color":"white"}}>Daily Deals
                                </Link>
                        </li>
                        <li className="mega-position">
                                <Link to='/all-products' style={{"color":"white"}}>All Products
                                </Link>
                        </li>
                        <li className="mega-position">
                                <Link to='/contact-us' style={{"color":"white"}}>Contact Us
                                </Link>
                        </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
  );
};

const mapStateToProps=(state)=>
{
    return{
        navbar_list:state.layout.navbar_list
    }
}

export default connect(mapStateToProps)(NavBar);
