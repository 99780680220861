export const FETCH_POSTS = 'FETCH_POSTS';
export const NEW_POST = 'NEW_POST';
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const CHECK_AUTHORIZATION = 'CHECK_AUTHORIZATION';
export const CHECK_AUTHORIZATION_FAIL = 'CHECK_AUTHORIZATION_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const USER_PROFILE = 'USER_PROFILE';
export const REGISTRATION_COMPLETE_SUCCESS = 'REGISTRATION_COMPLETE_SUCCESS';
export const SET_LAYOUT_INFO = 'SET_LAYOUT_INFO';
export const SET_LAYOUT_ERROR = 'SET_LAYOUT_ERROR';
export const SET_OWNER_INFO = 'SET_OWNER_INFO';
export const SET_OWNER_ERROR = 'SET_OWNER_ERROR';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const SET_MAINSLIDER_DATA = 'SET_MAINSLIDER_DATA';
export const SET_MAINSLIDER_ERROR = 'SET_MAINSLIDER_ERROR';
export const SET_SLIDER1_DATA = 'SET_SLIDER1_DATA';
export const SET_SLIDER1_ERROR = 'SET_SLIDER1_ERROR';
export const SET_SLIDER2_DATA = 'SET_SLIDER2_DATA';
export const SET_SLIDER2_ERROR = 'SET_SLIDER2_ERROR';
export const SET_SLIDER3_DATA = 'SET_SLIDER3_DATA';
export const SET_SLIDER3_ERROR = 'SET_SLIDER3_ERROR';
export const FETCH_ORDER_LIST = 'FETCH_ORDER_LIST';
export const ORDER_REQUEST = 'ORDER_REQUEST';
export const ORDER_CANCEL = 'ORDER_CANCEL';
export const ORDER_CANCEL_FAIL = 'ORDER_CANCEL_FAIL';
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const REMOVE_PRODUCT_FROM_CART = 'REMOVE_PRODUCT_FROM_CART';
export const INCREMENT_CART_ITEM_QUANTITY = 'INCREMENT_CART_ITEM_QUANTITY';
export const DECREMENT_CART_ITEM_QUANTITY = 'DECREMENT_CART_ITEM_QUANTITY';
export const REFRESH_CART = 'REFRESH_CART';
export const RESET_CART = 'RESET_CART';
export const BULK_UPDATE_CART = 'BULK_UPDATE_CART';
export const SET_ORDER_NOTE = 'SET_ORDER_NOTE';
export const SET_PREFERRED_DATE = 'SET_PREFERRED_DATE';
export const SET_PLACE_ORDER_LOADING = 'SET_PLACE_ORDER_LOADING';
export const RESET_PLACE_ORDER_LOADING = 'RESET_PLACE_ORDER_LOADING';
export const SET_ALL_PRODUCT_DATA = 'SET_ALL_PRODUCT_DATA';
export const RESET_ALL_PRODUCT_DATA = 'RESET_ALL_PRODUCT_DATA';
export const SET_ALL_PRODUCT_ERROR = 'SET_ALL_PRODUCT_ERROR';
export const PAGENATE_ALL_PRODUCT_DATA = 'PAGENATE_ALL_PRODUCT_DATA';
export const FORGOT_PASSWORD_SEND_LINK_SUCCESS = 'FORGOT_PASSWORD_SEND_LINK_SUCCESS';
export const FORGOT_PASSWORD_SEND_LINK_FAIL = 'FORGOT_PASSWORD_SEND_LINK_FAIL';
export const FORGOT_PASSWORD_LINK_STATUS = 'FORGOT_PASSWORD_LINK_STATUS';
export const SET_PRODUCT_DETAIL = 'SET_PRODUCT_DETAIL';
export const SET_PRODUCT_DETAIL_ERROR = 'SET_PRODUCT_DETAIL_ERROR';
export const RESET_PRODUCT_DETAIL = 'RESET_PRODUCT_DETAIL';
export const SET_SIMILAR_PRODUCT_DETAIL = 'SET_SIMILAR_PRODUCT_DETAIL';
export const SET_SIMILAR_PRODUCT_DETAIL_ERROR = 'SET_SIMILAR_PRODUCT_DETAIL_ERROR';
export const RESET_SIMILAR_PRODUCT_DETAIL = 'RESET_SIMILAR_PRODUCT_DETAIL';
export const PRODUCT_COMPLETE_LIST = 'PRODUCT_COMPLETE_LIST';
export const FETCH_PROFILE_DATA = 'FETCH_PROFILE_DATA';
export const SET_STATIC_PAGE_DATA = 'SET_STATIC_PAGE_DATA';
export const SET_TRACK_MY_ORDER = 'SET_TRACK_MY_ORDER';
export const RESET_TRACK_MY_ORDER = 'RESET_TRACK_MY_ORDER';
export const DEFAULT_TRACK_MY_ORDER = 'DEFAULT_TRACK_MY_ORDER';
