import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import { Table, Tag, Space, Avatar, Spin} from 'antd';
import {connect} from 'react-redux';

import {
    ADD_PRODUCT_TO_CART,
    DECREMENT_CART_ITEM_QUANTITY,
    INCREMENT_CART_ITEM_QUANTITY,
    REMOVE_PRODUCT_FROM_CART,
    REFRESH_CART
} from '../../actions/types';

import postOrder from '../../actions/postOrder';

class Checkout extends Component{

	columns=[
			{
				title: 'Product',
			    dataIndex: 'product_name',
			    key: 'product_name'
			},
			{
				title: 'Price',
			    dataIndex: 'price',
			    key: 'price',
			    render:(text,record)=>(
			    	<>
			    	${record.price}
			    	</>
			    	)
			},
			{
				title: 'Quantity',
			    dataIndex: 'quantity',
			    key: 'quantity',
			},
			{
				title: 'Amount',
			    dataIndex: 'amount',
			    key: 'amount',
			    render:(text, record)=>(
			    	<>
			    	${record.price*record.quantity}
			    	</>
			    	),
			}
	]


	render()
	{
		return(
			<>
			{/*Page introduction banner starts*/}
			    <div class="page-style-a">
			        <div class="container">
			            <div class="page-intro">
			                <h2>Checkout</h2>
			                <ul class="bread-crumb">
			                    <li class="has-separator">
			                        <i class="ion ion-md-home"></i>
			                        <Link to="/">Home</Link>
			                    </li>
			                    <li class="is-marked">
			                        <Link to="/checkout">Checkout</Link>
			                    </li>
			                </ul>
			            </div>
			        </div>
			    </div>
			{/*Page introduction banner ends*/} 

			{/*Cart Body Starts*/}

			    <div class="page-cart u-s-p-t-80">
        			<div class="container">
            			<div class="row">
                			<div class="col-sm-12 col-md-9">
                				          <Table dataSource={this.props.data} columns={this.columns}
                				          pagination={false} summary={() => (
																        <Table.Summary fixed>
																          <Table.Summary.Row>
																            <Table.Summary.Cell><b>Total</b></Table.Summary.Cell>
																            <Table.Summary.Cell></Table.Summary.Cell>
																            <Table.Summary.Cell><b>{Object.values(this.props.agg).reduce((a, b) => a + b)}</b></Table.Summary.Cell>
																            <Table.Summary.Cell><b>${this.props.data.length>0?this.props.data.reduce((a, b) => a + b.price*b.quantity,0):0}</b></Table.Summary.Cell>
																          </Table.Summary.Row>
																        </Table.Summary>
																      )}
											>
									       </Table>
                			</div>
                			<div class="col-sm-12 col-md-3">
                					<div  style={{"border": "1px solid #CCCCCC","color":"black","padding":"20px","margin-bottom":"20px"}}>
                					<div>
									    <label>Notes:</label>
									    <p>{this.props.note}</p>
									 </div>
									 <label>Preferred Date:</label>
									 <p>{this.props.preferred_date}</p>

									 {this.props.data.length>0?(
				                		<div style={{"text-align":"center"}}>
				                			{
				                				this.props.loading===0?(
				                					<button className="button button-outline-secondary" style={{"margin":"15px","padding-left":"20px","padding-right":"20px"}} onClick={()=>this.props.postOrder(this.props.data,this.props.note,this.props.preferred_date)}>Place Order</button>
				                					):(<Spin size="large"  tip="Placing Order...Please Wait."/>)
				                			}
				                		</div>
				                		):(<></>)

				                	}
				                	</div>
                			</div>
                		</div>
                	</div>
                	
                </div>	
            {/*Cart body ends*/}
            </>
			);
	}
};


const mapStateToProps=(state)=>{
	return {
		data:state.cart.cart,
		agg:state.cart.cart_agg,
		loading:state.cart.loading,
		note:state.cart.note,
		preferred_date:state.cart.preferred_date
	}
}

const mapDispatchToProps=(dispatch)=>
{
	return{
					addToCart:(item,quantity)=>{
			                        item['quantity']=quantity;
			                        dispatch({
			                            type:ADD_PRODUCT_TO_CART,
			                            payload:item
			                        })
			                    },
			        removeFromCart:(id)=>{
			                        dispatch({
			                            type:REMOVE_PRODUCT_FROM_CART,
			                            payload:id
			                        })
			                    },
			        increaseInCart:(id)=>{
			                        dispatch({
			                            type:INCREMENT_CART_ITEM_QUANTITY,
			                            payload:id
			                        })
			                    },
			        decreaseInCart:(id)=>{
			                        dispatch({
			                            type:DECREMENT_CART_ITEM_QUANTITY,
			                            payload:id
			                        })
			                    },
					refreshCart:()=>{dispatch({
						type:REFRESH_CART
					})},
					postOrder:(data, note, date)=>postOrder(dispatch,data,note,date)
							}
}

export default connect(mapStateToProps,mapDispatchToProps)(Checkout);

