import React,{useState,useEffect,Component} from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import ProductCard from "../ProductCard/ProductCard";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { Spin, Alert,Space } from 'antd';
import 'antd/dist/antd.css';

import {Link} from "react-router-dom";

const RelatedProducts=(props)=>{
    let responsive={
                    0:{
                        items:Math.min(1,props.similar_product.similar_product_detail.length)
                    },
                    500:{
                        items:Math.min(2,props.similar_product.similar_product_detail.length)
                    },
                    800:{
                      items:Math.min(3,props.similar_product.similar_product_detail.length)
                    },
                    1000:{
                      items:Math.min(4,props.similar_product.similar_product_detail.length)
                    },
                    1300:{
                      items:Math.min(5,props.similar_product.similar_product_detail.length)
                    }
                };
  let navText = ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'];


  return (
    <div key={`${props.current_product}`} className="container products-section" style={{"max-width":"100%","padding-left":"10%","padding-right":"10%", "padding-bottom":"100px", "padding-top":"25px"}}>
       <div className="sec-maker-header text-center">
                <h3 className="sec-maker-h3">Similar Products</h3>
                <ul className="nav tab-nav-style-1-a justify-content-center"  style={{"float":"right", "display":"block"}}>
                    <li className="nav-item">
                        <Link  to={props.similar_product.similar_product_detail.length>0?'/category/'+props.similar_product.similar_product_detail[0].product_category_name:'/all-products'} style={{"color":"#a50005"}}>See All</Link>
                    </li>
                </ul>
                <br />
        </div>
        {
        props.similar_product.similar_product_detail_status===1?(
            <OwlCarousel className="owl-theme"  margin={5} responsive={responsive} responsiveRefreshRate={200} navText={navText} nav>
          {
            props.similar_product.similar_product_detail.map((item,index) => {if(item.product_name.toLowerCase()!=props.current_product.toLowerCase()){return(
                <ProductCard image={item.image} name={item.product_name} category={item.product_category_name} id={item.product_id} data={item}/>
              )
          }
          }
            )
          }
        </OwlCarousel>):(<Spin size="large"  style={{position: "absolute", left: "50%",transform: "translate(-50%, 0%)"}}  tip="Loading..." />)
        }
    </div>

  );
};

const mapStateToProps=(state)=>{
  return{
    similar_product:state.similar_product
  }
}

export default connect(mapStateToProps,null)(RelatedProducts);
