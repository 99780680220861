import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

const PrivacyPolicy=(props)=>{
return(
		<>
		<div class="page-style-a">
	        <div class="container">
	            <div class="page-intro">
	                <h2>Privacy Policy</h2>
	                <ul class="bread-crumb">
	                    <li class="has-separator">
	                        <i class="ion ion-md-home"></i>
	                        <Link to="/">Home</Link>
	                    </li>
	                    <li class="is-marked">
	                        <Link to="/terms-and-condition">Privacy Policy</Link>
	                    </li>
	                </ul>
	            </div>
	        </div>
    	</div>
    	<div class="page-term u-s-p-t-80">
        <div class="container">
            <div dangerouslySetInnerHTML={ {__html: props.privacy_policy} } />
        </div>
    </div>
		</>
		);
}

const mapStateToProps=(state)=>
{
    return{
        privacy_policy:state.layout.owner_info.privacy_policy
    }
}

export default connect(mapStateToProps,null)(PrivacyPolicy);