import React, {Component} from 'react';
import {useParams,Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { Spin, Alert, Space } from "antd";

import {
	get_data
} from '../../actions/productDetailAction';

import {
    ADD_PRODUCT_TO_CART,
    DECREMENT_CART_ITEM_QUANTITY,
    INCREMENT_CART_ITEM_QUANTITY,
    REMOVE_PRODUCT_FROM_CART,
    REFRESH_CART
} from '../../actions/types';

import RelatedProducts from '../RelatedProducts/RelatedProducts';

class Product extends Component
{
	name =this.props.match.params.name;

	componentDidUpdate()
	{
			if(this.props.match.params.name != this.name)
			{

				this.name =this.props.match.params.name;
				this.props.get_data(this.name);
			}
			
	}

	componentDidMount()
	{
			this.props.get_data(this.name);
	}

	handleInput(qty)
	{
		console.log(qty);
		if(/^\d+$/.test(qty))
		{
			this.props.addToCart({...this.props.data.product_detail,"id":this.props.data.product_detail.product_id },parseInt(qty));
		}
		else if(qty==='')
		{
			this.props.addToCart({...this.props.data.product_detail,"id":this.props.data.product_detail.product_id },0);
		}
	}

	focusOut(id)
	{
		this.props.refreshCart();
	}

	render(){
		return(
		<>
	{/*Banner Section Start*/}
			<div className="page-style-a">
	        <div className="container">
	            <div className="page-intro">
	                <h2>Products</h2>
	                <ul className="bread-crumb">
	                    <li className="has-separator">
	                        <i className="ion ion-md-home"></i>
	                        <Link to="/">Home</Link>
	                    </li>
	                    <li className="is-marked">
	                        <Link to="/all-products">Product</Link>
	                    </li>
	                </ul>
	            </div>
	        </div>
    	</div>
   {/*Banner Section Ends*/}
    	<div class="page-detail u-s-p-t-80">
        	<div class="container">
        		<div class="row">

        	{/*Image Section Start*/}
	                <div class="col-md-6 col-sm-12">
	                    <div class="zoom-area">
   												{this.props.data.product_detail_status===1?(
   													<img id="zoom-pro" class="img-fluid" style={{"max-width":"300px","display":"block","margin-left": "auto","margin-right":"auto"}} src={`${this.props.data.product_detail.image}`} data-zoom-image={`${this.props.data.product_detail.image}`}  alt="Image" />
   													):(
   													<Spin
										          size="large"
										          tip="Loading...Please Wait."
										          style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)"}} 
										        />
   													)
   												}
	                        
	                    </div>
	                 </div>
	        {/*Image Section Ends*/}

	      	{/*Product Detail Section Starts*/}
	                 <div class="col-lg-6 col-md-6 col-sm-12">
		                 <div class="all-information-wrapper">
	                        <div class="section-1-title-breadcrumb-rating">
	                            <div class="product-title">
	                                <h1>
	                                    <Link to={`/product/${this.props.data.product_detail.product_name}`}>{this.props.data.product_detail.product_name}</Link>
	                                </h1>
	                            </div>
	                            <ul class="bread-crumb">
	                                <li class="has-separator">
	                                    <Link to="/">Home</Link>
	                                </li>
	                                <li class="has-separator">
	                                    <Link to={`/category/${this.props.data.product_detail.product_category_name}`}>{this.props.data.product_detail.product_category_name}</Link>
	                                </li>
                            	</ul>
	                        </div>
	                        <div class="section-2-short-description u-s-p-y-14">
	                            <h6 class="information-heading u-s-m-b-8">Description:</h6>
	                            <p>{this.props.data.product_detail.description?this.props.data.product_detail.description:'No Description Available'}</p>
                        	</div>
                        	{/*<div class="section-4-sku-information u-s-p-y-14">
	                            <h6 class="information-heading u-s-m-b-8">Sku Information:</h6>
	                            <div class="availability">
	                                <span>Availability:</span>
	                                <span>In Stock</span>
	                            </div>
	                            <div class="left">
	                                <span>Only:</span>
	                                <span>50 left</span>
	                            </div>
                        	</div>*/}
                        	<div class="section-4-sku-information u-s-p-y-14">
	                            <div>
	                                <span>Price:</span>
	                                <span>${this.props.data.product_detail.price}</span>
	                            </div>
                        	</div>
                        	<div class="section-6-social-media-quantity-actions u-s-p-y-14">
	                        		<div class="quantity-wrapper u-s-m-b-22" style={{"display":this.props.data.product_detail_status===1?'block':'none'}}>
	                        		            {
									                (this.props.data.product_detail.product_id in this.props.cart.cart_agg)===true?(
									                    <>	
									                    <span>Quantity:</span>
									                    <div class="quantity">
										                    <input type="text" class="quantity-text-field" 
										                    	value={this.props.cart.cart_agg[this.props.data.product_detail.product_id]} 
										                    	onChange={(e)=>this.handleInput(e.target.value)} 
										                    	onBlur={()=>this.focusOut(this.props.data.product_detail.product_id)}/>
					                                        <a class="plus-a" onClick={()=>this.props.increaseInCart(this.props.data.product_detail.product_id)}>&#43;</a>
					                                        <a class="minus-a" onClick={()=>this.props.decreaseInCart(this.props.data.product_detail.product_id)}>&#45;</a>
					                                    </div>
					                                    <button className="button button-outline-secondary" style={{"margin":"5px"}} type="button" onClick={()=>this.props.removeFromCart(this.props.data.product_detail.product_id)}><i class="fa fa-times"></i>&nbsp;Remove From Cart</button>
									                    </>
									                    ):(<button className="button button-outline-secondary" type="button" onClick={()=>this.props.addToCart({...this.props.data.product_detail,"id":this.props.data.product_detail.product_id },1)}><i class="ion ion-md-basket"></i>&nbsp;Add to Cart</button>)
									            }
	                                </div>
                        	</div>
	                     </div>
	                 </div>
	        {/*Product Detail Section Ends*/}

              </div>
        	</div>
    	</div>

    {/*Related Products Starts*/}
    	<RelatedProducts key={JSON.stringify(this.props.related_products)} current_product={`${this.name}`}/>
    {/*Related Products*/}
		</>
		);
}
} 

const mapDispatchToProps=(dispatch)=>
{
	return{
					get_data:(name)=>get_data(dispatch,name),
					addToCart:(item,quantity)=>{
			                        item['quantity']=quantity;
			                        dispatch({
			                            type:ADD_PRODUCT_TO_CART,
			                            payload:item
			                        })
			                    },
			        removeFromCart:(id)=>{
			                        dispatch({
			                            type:REMOVE_PRODUCT_FROM_CART,
			                            payload:id
			                        })
			                    },
			        increaseInCart:(id)=>{
			                        dispatch({
			                            type:INCREMENT_CART_ITEM_QUANTITY,
			                            payload:id
			                        })
			                    },
			        decreaseInCart:(id)=>{
			                        dispatch({
			                            type:DECREMENT_CART_ITEM_QUANTITY,
			                            payload:id
			                        })
			                    },
					refreshCart:()=>{dispatch({
						type:REFRESH_CART
					})}
							}
}

const mapStateToProps=(state)=>
{
	return{
					data:state.product_detail,
					cart:state.cart,
					related_products:state.similar_product
				}
}


export default connect(mapStateToProps,mapDispatchToProps)(Product);