import React from "react";
import NewsLetter from "./components/NewsLetter";
import FooterLinks from "./components/FooterLinks";
import GitLinks from "./components/GitLink";
import "./Footer.css";

const Footer = () => {
  return (
    <React.Fragment>
      <FooterLinks />
    </React.Fragment>
  );
};

export default Footer;
