/**
  Link Start From axios baseURL which is defined in api.js in  services folder. 
  i.e baseURL+"additional links"

 */
export const baseURL = 'https://testwebdemo.ml/api/v1';
//export const baseURL = 'http://localhost/ktmtrading/api/v1';

// auth/user links
export const registerUserLink = `${baseURL}/registers`;
export const loginUserLink = `${baseURL}/login-user`;
export const changePasswordLink = `${baseURL}/change-password`;
export const signUp = `${baseURL}/registers`;
export const forgotPassword = `${baseURL}/forgot-password`;
export const userProfile = `${baseURL}/getuser`;
export const userUpdate = `${baseURL}/update-profile`;

//layout and navbar information link
export const layoutinfoLink = `${baseURL}/category-list`;
export const ownerinfoLink = `${baseURL}/owner_info`;

//homepage information link
export const homepagemainsliderLink = `${baseURL}/home-page-main-slider`;
export const homepageslider1Link = `${baseURL}/home-page-slider-1`;
export const homepageslider2Link = `${baseURL}/home-page-slider-2`;
export const homepageslider3Link = `${baseURL}/home-page-slider-3`;
export const orderListLink = `${baseURL}/user-order-history`;
export const orderCancelLink = `${baseURL}/user-order-cancel`;


//all_product link
export const allproductLink = `${baseURL}/product-list`;

//post order
export const orderPostLink = `${baseURL}/order-post`;

//static pages
export const staticPageLink = `${baseURL}/static_page_info`;

//track my order
export const trackOrderLink = `${baseURL}/track_my_order`;

