import { SET_LAYOUT_INFO, SET_LAYOUT_ERROR,SET_OWNER_INFO, SET_OWNER_ERROR } from './types';
import {layoutinfoLink, ownerinfoLink} from '../services/apiLink';
import {get_data} from './productCompleteListAction';

export const getnavinfo = (dispatch) => {
  let result = fetch(layoutinfoLink, {
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    })
      .then((res) => {
          if (res.ok)
          {
            return res.json();
          }
          else {
            throw new Error('Something went wrong');
          }
      })
      .then(
        (result) => {
          dispatch({
                type: SET_LAYOUT_INFO,
                payload:result.data
              }
              )
          get_data(dispatch);
        }
      )
      .catch(
        (error) => {
                      dispatch({
                type: SET_LAYOUT_ERROR,
                payload:[]
              }
              )
          }
        )
  };
  

export const getownerinfo = (dispatch) => {
  let result = fetch(ownerinfoLink, {
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    })
      .then((res) => {
          if (res.ok)
          {
            return res.json();
          }
          else {
            throw Error('Something went wrong on owner information');
          }
      })
      .then(
        (result) => {
          console.log(result);
          dispatch({
                type: SET_OWNER_INFO,
                payload:result.data[0]
              }
              )
        }
      )
      .catch(
        (error) => {
                      dispatch({
                type: SET_OWNER_ERROR,
                payload:{}
              }
              )
          }
        )
  };
  
