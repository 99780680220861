import React, { Component } from "react";
import { List, Avatar } from "antd";
import { connect } from "react-redux";

class OrderListView extends Component {
  render() {
    //console.log(this.props.dataFromParent);
    return (
      <>
        <List
          itemLayout="horizontal"
          dataSource={this.props.dataFromParent.orderDetails}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar size={70} src={item.image} />}
                title={<a href="https://ant.design">{item.product_title}</a>}
                description={'Price: $'+item.price}
              />
              <List.Item.Meta
                title={`qty: ${item.quantity}`}
                description={`Amount: $${item.quantity*item.price}`}
              />
            </List.Item>
          )}
        />
      </>
    );
  }
}

export default connect(null)(OrderListView);
