import React,{Component} from "react";
import MainWrapper from "../MainWrapper/MainWrapper";
import TopHeader from "../TopHeader/TopHeader";
import MiddleHeader from "../MiddleHeader/MiddleHeader";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";

import { Spin, Alert, Space } from "antd";
import "antd/dist/antd.css";

import { connect } from "react-redux";

class MainLayout extends Component{

render(){
  if (
      this.props.layout_nav_info.nav_state === 1 &&
      this.props.layout_owner_info.owner_state === 1
    ) {
  return (
    <React.Fragment>
      <MainWrapper>
          <header>
            <TopHeader />
            <MiddleHeader />
            <NavBar />
          </header>
          <main>
            {this.props.children}
          </main>
          <footer>
            <Footer />
          </footer>
      </MainWrapper>
    </React.Fragment>
  );
    } else if (
          this.props.layout_nav_info.nav_state === -1 ||
          this.props.layout_owner_info.owner_state === -1
        ) {
          return (
            <div
              style={{
                position: "absolute",
                left: "40vw",
                top: "40vh",
                color: "red",
              }}
            >
              Oops....Something went wrong.
            </div>
          );
        } else {
          return (
            <Spin
              size="large"
              tip="Loading...Please Wait."
              style={{ position: "absolute", left: "50vw", top: "50vh", transform: "translate(-50%, -50%)"}}
            />
          );
        }

}

}

const mapStateToProps=(state)=>{
  return {
    layout_nav_info:state.layout,
    layout_owner_info:state.layout
  };
}

export default connect(mapStateToProps,null)(MainLayout);
