import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_PROFILE,
  REGISTRATION_COMPLETE_SUCCESS,
  LOGIN_REQUEST,
  FORGOT_PASSWORD_SEND_LINK_FAIL,
  FORGOT_PASSWORD_SEND_LINK_SUCCESS,
  FORGOT_PASSWORD_LINK_STATUS,
  REGISTER_REQUEST,
  FETCH_PROFILE_DATA
} from "../actions/types";

const initialState = {
  isAuthenticated: (window.localStorage.getItem("token") && true) || false,
  isLoading: false,
  user: null,
  forgotPasswordData: {},
  isLoadingRegistration: false,
  profile_data : null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case USER_PROFILE:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
      };
    case REGISTER_REQUEST:
      return {
        ...state,
        isLoadingRegistration: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoadingRegistration: false,
      };
    case AUTH_ERROR:
    case LOGIN_REQUEST:
      return {
        isLoading: true,
      };
    case LOGIN_FAIL:
      return {
        isAuthenticated: false,
        isLoading: false,
        isLoadingRegistration: false,
      };
    case LOGOUT_SUCCESS:
      //window.localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoadingRegistration: false,
      };
    case REGISTRATION_COMPLETE_SUCCESS:
      return {
        ...state,
      };
    case FORGOT_PASSWORD_LINK_STATUS:
      return {
        ...state,
        isLoading: true,
      };
    case FORGOT_PASSWORD_SEND_LINK_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case FORGOT_PASSWORD_SEND_LINK_FAIL:
      return {
        ...state,
      };
    case FETCH_PROFILE_DATA:
      return {
        ...state,
        isLoading: false,
        profile_data: action.payload,
      };
    default:
      return state;
  }
}
