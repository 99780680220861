import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import { Table, Tag, Space, Avatar,  DatePicker} from 'antd';
import moment from 'moment';
import {connect} from 'react-redux';

import {
    ADD_PRODUCT_TO_CART,
    DECREMENT_CART_ITEM_QUANTITY,
    INCREMENT_CART_ITEM_QUANTITY,
    REMOVE_PRODUCT_FROM_CART,
    REFRESH_CART,
    SET_ORDER_NOTE,
    SET_PREFERRED_DATE
} from '../../actions/types';

class Cart extends Component{
	handleInput=(data,qty)=>
    {
        console.log(qty);
        if(/^\d+$/.test(qty))
        {
            this.props.addToCart({...data,"id":data.product_id },parseInt(qty));
        }
        else if(qty==='')
        {
            this.props.addToCart({...data,"id":data.product_id },0);
        }
    }

    focusOut=(id)=>
    {
        this.props.refreshCart();
    }

    dateFormat = 'YYYY/MM/DD';

	columns=[
			{
				title: 'Product',
			    dataIndex: 'image',
			    key: 'image',
			    render: image =>  <Avatar size={70} src={image} />
			},
			{
				title: 'Name',
			    dataIndex: 'product_name',
			    key: 'product_name'
			},
			{
				title: 'Price',
			    dataIndex: 'price',
			    key: 'price',
			    render:(text,record)=>(
			    	<>
			    	${record.price}
			    	</>
			    	)
			},
			{
				title: 'Quantity',
			    dataIndex: 'quantity',
			    key: 'quantity',
			    render:(text, record)=>(
			    	<div class="quantity">
                          <input type="text" class="quantity-text-field" 
                                value={this.props.agg[record.product_id]} 
                                        onChange={(e)=>this.handleInput(record,e.target.value)} 
                                        onBlur={()=>this.focusOut(record.product_id)}/>
                          <a class="plus-a" onClick={()=>this.props.increaseInCart(record.product_id)}>&#43;</a>
                          <a class="minus-a" onClick={()=>this.props.decreaseInCart(record.product_id)}>&#45;</a>
			    	</div>
			    	),
			},
			{
				title: 'Action',
			    dataIndex: 'action',
			    key: 'action',
			    render: (text, record) => (
			    	<button className="button button-outline-secondary" style={{"margin":"5px"}} type="button" onClick={()=>this.props.removeFromCart(record.product_id)}><i class="fas fa-trash-alt"></i></button>				                    
			    	)
			}
	]


	render()
	{

		return(
			<>
			{/*Page introduction banner starts*/}
			    <div class="page-style-a">
			        <div class="container">
			            <div class="page-intro">
			                <h2>Cart</h2>
			                <ul class="bread-crumb">
			                    <li class="has-separator">
			                        <i class="ion ion-md-home"></i>
			                        <Link to="/">Home</Link>
			                    </li>
			                    <li class="is-marked">
			                        <Link to="/my-cart">Cart</Link>
			                    </li>
			                </ul>
			            </div>
			        </div>
			    </div>
			{/*Page introduction banner ends*/} 

			{/*Cart Body Starts*/}

			    <div class="page-cart u-s-p-t-80">
        			<div class="container">
            			<div class="row">
                			<div class="col-sm-12 col-md-9">
                				          <Table dataSource={this.props.data} columns={this.columns} >
									       </Table>
                			</div>
                			<div class="col-sm-12 col-md-3">
                					<div  style={{"border": "1px solid #CCCCCC","color":"black","padding":"20px","margin-bottom":"20px"}}>
                					<div class="form-group">
									    <label for="textarea">Add Notes:</label>
									    <textarea class="form-control" id="textarea" rows="3" value={this.props.note}
									     onChange={e => this.props.setNote(e.target.value)}></textarea>
									 </div>
									 <label>Preferred Date:</label>
									 <DatePicker format={this.dateFormat} 
									 onChange={(date, dateString)=> this.props.setDate(dateString)}
									 
									 />
                				    {this.props.data.length>0?(
				                		<div style={{"text-align":"center"}}>
				                			<Link  to="/checkout" ><button className="button button-outline-secondary" style={{"margin":"15px","padding-left":"20px","padding-right":"20px"}} >Checkout</button></Link>
				                		</div>
				                		):(<></>)

				                	}
				                	</div>
                			</div>
                		</div>
                	</div>
                	
                	
                </div>	
            {/*Cart body ends*/}
            </>
			);
	}
};


const mapStateToProps=(state)=>{
	return {
		data:state.cart.cart,
		agg:state.cart.cart_agg,
		note:state.cart.note,
		preferred_date:state.cart.preferred_date
	}
}

const mapDispatchToProps=(dispatch)=>
{
	return{
					addToCart:(item,quantity)=>{
			                        item['quantity']=quantity;
			                        dispatch({
			                            type:ADD_PRODUCT_TO_CART,
			                            payload:item
			                        })
			                    },
			        removeFromCart:(id)=>{
			                        dispatch({
			                            type:REMOVE_PRODUCT_FROM_CART,
			                            payload:id
			                        })
			                    },
			        increaseInCart:(id)=>{
			                        dispatch({
			                            type:INCREMENT_CART_ITEM_QUANTITY,
			                            payload:id
			                        })
			                    },
			        decreaseInCart:(id)=>{
			                        dispatch({
			                            type:DECREMENT_CART_ITEM_QUANTITY,
			                            payload:id
			                        })
			                    },
					refreshCart:()=>{dispatch({
						type:REFRESH_CART
					})},
					setNote:(notes)=>{
						dispatch({
						type:SET_ORDER_NOTE,
						payload: notes
					})
					},
					setDate:(date)=>{
						dispatch({
						type:SET_PREFERRED_DATE,
						payload: date
					})
					}
				}
}

export default connect(mapStateToProps,mapDispatchToProps)(Cart);

