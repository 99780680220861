import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import { Spin, Alert,Space } from 'antd';

import {
get_home_page_main_slider_data
} from '../../actions/HomeActions'



class HomeSlider extends Component {

  render() {
    var settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
      pauseOnHover: false
    };

    let contentStyle = {
      "width":"100%",
      "height":"30vw",
      "verticalAlign": "middle"
    }

    let buttonStyle = {
            "margin": "0",
            "padding":"10px",
            "padding-left":"50px",
            "padding-right":"50px",
            "border-radius":"40px",
            "position": "absolute",
            "top": "90%",
            "left": "50%",
            "-ms-transform": "translate(-50%, -50%)",
            "transform": "translate(-50%, -50%)",
            "background-color":"#1A004A",
            "border":"0px"
               }

    if(this.props.mainslider_status===1){
          return (
              <div style={{"overflowX":"hidden","overflowY":"hidden"}}>
                <Slider {...settings}>
                {
                  this.props.mainslider_data.map((slider,index)=>
                    {return(
                    <div>
                         <div style={{"display":"block", "position":"relative","textAlign":"center","verticalAlign": "middle","height":"30vw","overflow":"Hidden"}}>
                            <img style={contentStyle} src={`${slider.image}`}/>
                            <Link to={`/category/${slider.product_category_name}`}><button className="btn btn-primary" style={buttonStyle}>Shop Now</button></Link>
                         </div>
                    </div>
                      )
                  }
                    )
                }
                </Slider>
              </div>
            );
    }
    else if (this.props.mainslider_status===0)
    {
      return(
        <div style={{"height":"40vw","width":"100vw"}}><Spin size="large"  tip="Loading...Please Wait." style={{position: "absolute", left: "50vw",transform: "translate(-50%, 50%)"}}/></div>
        )
    }
    else{
      return(
          <></>
        )
    }

  }
}

const mapStateToProps=(state)=>{
  return{
    mainslider_status:state.home.mainslider_status,
    mainslider_data:state.home.mainslider_data
  }
}

export default connect(mapStateToProps,null)(HomeSlider);
