import React from "react";
import { useParams, Link } from "react-router-dom";
import MapPage from '../../components/Map/MapPage';

const Contact = (props) => {
  return (
    <>
      <div className="page-style-a">
        <div className="container">
          <div className="page-intro">
            <h2>Contact</h2>
            <ul className="bread-crumb">
              <li className="has-separator">
                <i className="ion ion-md-home"></i>
                <Link to="/">Home</Link>
              </li>
              <li className="is-marked">
                <Link to="/all-products">Contact</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="page-contact u-s-p-t-80">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="touch-wrapper">
                <h1 class="contact-h1">Get In Touch With Us</h1>
                <form>
                  <div class="group-inline u-s-m-b-30">
                    <div class="group-1 u-s-p-r-16">
                      <label for="contact-name">
                        Your Name
                        <span class="astk">*</span>
                      </label>
                      <input
                        type="text"
                        id="contact-name"
                        class="text-field"
                        placeholder="Name"
                      />
                    </div>
                    <div class="group-2">
                      <label for="contact-email">
                        Your Email
                        <span class="astk">*</span>
                      </label>
                      <input
                        type="text"
                        id="contact-email"
                        class="text-field"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div class="u-s-m-b-30">
                    <label for="contact-subject">
                      Subject
                      <span class="astk">*</span>
                    </label>
                    <input
                      type="text"
                      id="contact-subject"
                      class="text-field"
                      placeholder="Subject"
                    />
                  </div>
                  <div class="u-s-m-b-30">
                    <label for="contact-message">Message:</label>
                    <textarea class="text-area" id="contact-message"></textarea>
                  </div>
                  <div class="u-s-m-b-30">
                    <button
                      type="submit"
                      class="button button-outline-secondary"
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="contact-us-wrapper">
                <h1 class="contact-h1">Contact Us(Head Office)</h1>
                <div class="contact-material u-s-m-b-16">
                  <h6>Location</h6>
                  <span> 2/29 Frederick Street, Rockdale,</span>
                  <span> Sydney, NSW 2216</span>
                  <span> Australia</span>
                </div>
                <div class="contact-material u-s-m-b-16">
                  <h6>Email</h6>
                  <span>info@kathmandutrading.com.au</span>
                </div>
                <div class="contact-material u-s-m-b-16">
                  <h6>Phone</h6>
                  <span>+612 8964 6122</span>
                </div>
              </div>
              <div class="contact-us-wrapper">
                <h1 class="contact-h1">Contact Us(Corporate Office)</h1>
                <div class="contact-material u-s-m-b-16">
                  <h6>Location</h6>
                  <span> Amrit Marga-29,</span>
                  <span> Thamel, Kathmandu</span>
                  <span>Nepal</span>
                </div>
                <div class="contact-material u-s-m-b-16">
                  <h6>Email</h6>
                  <span>info@kathmandutrading.com.au</span>
                </div>
                <div class="contact-material u-s-m-b-16">
                  <h6>Telephone</h6>
                  <span> +977 01 4472040</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="u-s-p-t-80">
          <div id="map">
            <MapPage/>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
