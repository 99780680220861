import { combineReducers } from 'redux';
import clientReducer from './clientReducer';
import authReducer from './authReducer';
import layoutReducer from './layoutReducer';
import homeReducer from './homeReducer';
import orderReducer from './orderReducer';
import cartReducer from './cartReducer';
import allProductReducer from './allProductReducer';
import ProductDetailReducer from './ProductDetailReducer';
import similarProductDetailReducer from './similarProductReducer';
import ProductCompleteListReducer from './ProductCompleteListReducer';
import trackMyOrderReducer from './trackMyOrderReducer';

export default combineReducers({
  posts: clientReducer,
  auth:authReducer,
  layout:layoutReducer,
  home:homeReducer,
  orders:orderReducer,
  cart:cartReducer,
  all_product:allProductReducer,
  product_detail:ProductDetailReducer,
  similar_product:similarProductDetailReducer,
  product_complete_list:ProductCompleteListReducer,
  track_my_order:trackMyOrderReducer
});