import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { login, register } from "../../actions/AuthActions";
import { Form, Icon, Input, Button, Spin, Alert, notification } from "antd";
import "antd/lib/spin/style/index.css";
import { LOGIN_FAIL } from "../../actions/types";
import { Link } from "react-router-dom";

const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
  });
};
class SignUpPage extends Component {
  state = {
    email: "",
    password: "",
    name: "",
    phone: "",
    confirm_password: "",
    address: "",
    city: "",
    state: "",
    post_code: "",
    organization: "",
    abn: "",
    attention: "",
    loading: false,
    visible: false,
    loadingRegister: false,
  };

  static propTypes = {
    isAuthenticated: PropTypes.bool,
    error: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps) {
    const { error, isAuthenticated } = this.props;
    //console.log(this.props);
    if (error !== prevProps.error) {
      // Check for login error
      if (error.id === "LOGIN_FAIL") {
        this.setState({ msg: error.msg });
      } else {
        this.setState({ msg: null });
      }
    }
    if (isAuthenticated) {
      this.props.history.push("/");
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const newLogin = {
      email: this.state.email,
      password: this.state.password,
    };
    // this.setState({
    //   loading: true
    // });

    return new Promise((resolve, reject) => {
      this.props.login(newLogin, resolve, reject);
    })
      .then(() => {
        this.setState({
          loading: false,
        });
        this.props.history.push("/");
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  handleRegisterSubmit = (e) => {
    e.preventDefault();
    console.log(this.state.email);
    let formData = new FormData(e.target);
    formData.append("name", this.state.name);
    formData.append("attention", this.state.attention);
    formData.append("email", this.state.email.replace(/\s/g, ""));
    formData.append("mobile", this.state.phone);
    formData.append("address", this.state.address);
    formData.append("city", this.state.city);
    formData.append("state", this.state.state);
    formData.append("post_code", this.state.post_code);
    formData.append("abn", this.state.abn);
    formData.append("organization", this.state.organization);
    formData.append("password", this.state.password);
    formData.append("password_confirmation", this.state.confirm_password);
    console.log(formData);
    this.props.register({
      signUpDetails: formData,

      onSuccess: () => {
        const type = "success";
        const message = "Success";
        const description = "Sign Up successful.Please Sign in";
        openNotificationWithIcon(type, message, description);
        //alert('Sign Up successful.Please Sign in!');
        this.props.history.push("/");
      },
      onFailure: (errorMsg) => {
        // this.toggleLogin(false);
        alert(errorMsg);

        //Alert error message
      },
    });
  };

  inputEmailChangeHandler = (event) => {
    this.setState({ email: event.target.value });
  };
  inputPasswordChangeHandler = (event) => {
    this.setState({ password: event.target.value });
  };

  inputOrganizationChangeHandler = (event) => {
    this.setState({ organization: event.target.value });
  };

  inputAttentionChangeHandler = (event) => {
    this.setState({ name: event.target.value });
  };

  inputPhoneChangeHandler = (event) => {
    this.setState({ phone: event.target.value });
  };

  inputAddressChangeHandler = (event) => {
    this.setState({ address: event.target.value });
  };

  inputCityChangeHandler = (event) => {
    this.setState({ city: event.target.value });
  };

  inputStateChangeHandler = (event) => {
    this.setState({ state: event.target.value });
  };

  inputPostalCodeChangeHandler = (event) => {
    this.setState({ postal_code: event.target.value });
  };

  inputConfirmPasswordChangeHandler = (event) => {
    this.setState({ confirm_password: event.target.value });
  };

  inputAbnChangeHandler = (event) => {
    this.setState({ abn: event.target.value });
  };

  render() {
    console.log("state", this.props.loadingRegister);
    //const { loading } = this.state;
    return (
      <>
        <div class="page-style-a">
          <div class="container">
            <div class="page-intro">
              <h2>Account</h2>
              <ul class="bread-crumb">
                <li class="has-separator">
                  <i class="ion ion-md-home"></i>
                  <a href="/">Home</a>
                </li>
                <li class="is-marked">
                  <a href="account.html">Account</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="page-account u-s-p-t-80">
          <div class="container">
            <div class="row">
              <div
                class="col-lg-6 border-0 shadow rounded-3 my-5"
                style={{ margin: "0% 0px 0px 25%", height: "100%" }}
              >
                <div class="reg-wrapper">
                  <form onSubmit={this.handleRegisterSubmit}>
                    <Spin spinning={this.props.loadingRegister}>
                      <h2 class="account-h2 u-s-m-b-20">Register</h2>
                      <h6 class="account-h6 u-s-m-b-30">
                        Registering for this site allows you to access your
                        order status and history.
                      </h6>
                      <div class="u-s-m-b-30">
                        <label for="user-name">
                          Organization / Bussiness Name
                          <span class="astk">*</span>
                        </label>
                        <input
                          type="text"
                          id="organization"
                          class="text-field"
                          value={this.state.value}
                          onChange={this.inputOrganizationChangeHandler}
                          placeholder="organization"
                        />
                      </div>
                      <div class="u-s-m-b-30">
                        <label for="abn">
                          ABN
                          <span class="astk">*</span>
                        </label>
                        <input
                          type="text"
                          id="abn"
                          class="text-field"
                          value={this.state.value}
                          onChange={this.inputAbnChangeHandler}
                          placeholder="ABN"
                        />
                      </div>
                      <div class="u-s-m-b-30">
                        <label for="attention">
                          Attention
                          <span class="astk">*</span>
                        </label>
                        <input
                          type="text"
                          id="name"
                          class="text-field"
                          value={this.state.value}
                          onChange={this.inputAttentionChangeHandler}
                          placeholder="Attention"
                        />
                      </div>
                      <div class="u-s-m-b-30">
                        <label for="email">
                          Email
                          <span class="astk">*</span>
                        </label>
                        <input
                          type="text"
                          id="email"
                          class="text-field"
                          value={this.state.value}
                          onChange={this.inputEmailChangeHandler}
                          placeholder="Email"
                        />
                      </div>
                      <div class="u-s-m-b-30">
                        <label for="phone">
                          phone
                          <span class="astk">*</span>
                        </label>
                        <input
                          type="text"
                          id="phone"
                          class="text-field"
                          value={this.state.value}
                          onChange={this.inputPhoneChangeHandler}
                          placeholder="phone"
                        />
                      </div>
                      <div class="u-s-m-b-30">
                        <label for="address">
                          address
                          <span class="astk">*</span>
                        </label>
                        <input
                          type="text"
                          id="address"
                          class="text-field"
                          value={this.state.value}
                          onChange={this.inputAddressChangeHandler}
                          placeholder="address"
                        />
                      </div>
                      <div class="u-s-m-b-30">
                        <label for="city">
                          city
                          <span class="astk">*</span>
                        </label>
                        <input
                          type="text"
                          id="city"
                          class="text-field"
                          value={this.state.value}
                          onChange={this.inputCityChangeHandler}
                          placeholder="city"
                        />
                      </div>
                      <div class="u-s-m-b-30">
                        <label for="state">
                          state
                          <span class="astk">*</span>
                        </label>
                        <input
                          type="text"
                          id="state"
                          class="text-field"
                          value={this.state.value}
                          onChange={this.inputStateChangeHandler}
                          placeholder="state"
                        />
                      </div>
                      <div class="u-s-m-b-30">
                        <label for="Postal Code">
                          Postal Code
                          <span class="astk">*</span>
                        </label>
                        <input
                          type="text"
                          id="PostalCode"
                          class="text-field"
                          value={this.state.value}
                          onChange={this.inputPostalCodeChangeHandler}
                          placeholder="Postal Code"
                        />
                      </div>
                      <div class="u-s-m-b-30">
                        <label for="password">
                          Password
                          <span class="astk">*</span>
                        </label>
                        <input
                          type="password"
                          id="password"
                          class="text-field"
                          value={this.state.value}
                          onChange={this.inputPasswordChangeHandler}
                          placeholder="Password"
                        />
                      </div>
                      <div class="u-s-m-b-30">
                        <label for="Confirm Password">
                          Confirm Password
                          <span class="astk">*</span>
                        </label>
                        <input
                          type="password"
                          id="ConfirmPassword"
                          class="text-field"
                          value={this.state.value}
                          onChange={this.inputConfirmPasswordChangeHandler}
                          placeholder="Confirm Password"
                        />
                      </div>
                      {/* <div class="u-s-m-b-30">
                    <input type="checkbox" class="check-box" id="accept" />
                    <label class="label-text no-color" for="accept">
                      I’ve read and accept the
                      <a href="terms-and-conditions.html" class="u-c-brand">
                        terms & conditions
                      </a>
                    </label>
                  </div> */}
                      <div class="u-s-m-b-45">
                        <button class="button button-primary w-100">
                          Register
                        </button>
                      </div>
                    </Spin>
                  </form>
                </div>
                <div class="group-2 text-right">
                    <div class="page-anchor">
                      <Link to="/sign-in">
                        <i class="fas fa-circle-o-notch u-s-m-r-9"></i>
                        Already Have Account?Sign In
                      </Link>
                      {/* <a href="#">
                              <i class="fas fa-circle-o-notch u-s-m-r-9"></i>
                              Lost your password?
                            </a> */}
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  test1: state,
  isAuthenticated: state.auth.isAuthenticated,
  error: state.auth.error,
  loading: state.auth.isLoading,
  loadingRegister: state.auth.isLoadingRegistration,
});

export default connect(mapStateToProps, { login, register })(SignUpPage);
