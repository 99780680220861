import React,{useState} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import { useHistory } from 'react-router-dom';

import GlobalSearch from '../GlobalSearch/GlobalSearch'

const MiddleHeader = (props) => {

  return (
    <React.Fragment>
              <div className="full-layer-mid-header" style={{"padding":"5px"}}>
            <div className="container">
                <div className="row clearfix align-items-center">
                    <div className="col-md-3 col-12">
                        <div className="brand-logo text-center">
                            <Link to="/" className="d-none d-lg-block">
                                <img src={props.logo} alt="Brand Logo" className="app-brand-logo" style={{"width":"80px"}} />
                            </Link>
                            <Link to="/" className="d-lg-none">
                                <img src={props.logo} alt="Brand Logo" className="app-brand-logo" style={{"width":"60px"}} />
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-5 col-12" style={{"height":"40px"}}>
                        <GlobalSearch />
                         {/*<form className="form-searchbox" >
                            <label className="sr-only" htmlFor="search-landscape">Search</label>
                            <input id="search-landscape" type="text" className="text-field" placeholder="Search everything" onChange={(e)=>setQuery(e.target.value)}/>
                            <button id="btn-search" type="submit" className="button button-primary fas fa-search" onClick={search_page}></button>
                        </form>*/}
                    </div>
                    <div className="col-lg-3 col-md-4 col-12" style={{"marginTop":"20px","marginBottom":"8px"}}>
                        <nav>
                            <ul className="mid-nav g-nav" style={{"textAlign":"center"}}>
                                <li  style={{"marginLeft": "12px","marginRight": "12px"}}>
                                    <Link to="/">
                                        <i className="ion ion-md-home u-c-brand"></i>
                                    </Link>
                                </li>
                                <li style={{"marginLeft": "12px","marginRight": "12px"}}>
                                    <Link id="mini-cart-trigger" to="/my-cart">
                                        <i className="ion ion-md-basket"></i>
                                        <span className="item-counter">{Object.values(props.cart.cart_agg).reduce((a, b) => a + b)}</span>
                                        {/*<span className="item-price">$220.00</span>*/}
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
  );
};

const mapStateToProps=(state)=>{
    return{
        logo:state.layout.owner_info.logo,
        cart:state.cart
    }
}
export default connect(mapStateToProps)(MiddleHeader);
