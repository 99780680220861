import React,{useState,useEffect,Component} from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import ProductCard from "../ProductCard/ProductCard";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { Spin, Alert,Space } from 'antd';
import 'antd/dist/antd.css';

import {Link} from "react-router-dom";

const All_Products=(props)=>{

  return (
    <div className="container products-section" style={{"max-width":"100%","padding-left":"10%","padding-right":"10%", "padding-bottom":"100px", "padding-top":"25px"}}>
       <div className="sec-maker-header text-center">
                <h3 className="sec-maker-h3">All Products</h3>
                <ul className="nav tab-nav-style-1-a justify-content-center"  style={{"float":"right", "display":"block"}}>
                    <li className="nav-item">
                        <Link  to='/all-products' style={{"color":"#a50005"}}>See All</Link>
                    </li>
                </ul>
                <br />
        </div>
        {
          props.status===1?(
            <OwlCarousel className="owl-theme"  margin={5} responsive={props.responsive} responsiveRefreshRate={200} navText={props.navText} nav>
          {
            props.data.map((item,index) => {return (
                <ProductCard image={item.image} name={item.product_name} category={item.product_category_name}  id={item.product_id} data={item}/>
              )
          }
            )
          }
        </OwlCarousel>):(<Spin size="large"  style={{position: "absolute", left: "50vw",transform: "translate(-50%, 0%)"}}  tip="Loading..." />)
        }
    </div>

  );
};

const mapStateToProps=(state)=>{
  return{
    data:state.home.slider3_data,
    status:state.home.slider3_status
  }
}

export default connect(mapStateToProps,null)(All_Products);
