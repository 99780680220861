import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import App from './components/App';
import { Provider } from "react-redux";
import store from "./store";
import * as serviceWorker from './serviceWorker';

import "./index.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./Design/css/fontawesome.min.css";
import "./Design/css/ionicons.min.css";
import "./Design/css/utility.css";
import "./Design/css/bundle.css";


const app = (
  <Provider store={store}>
    <BrowserRouter>
          <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));