import React,{Component} from "react";
import HomeSlider from '../HomeSlider/HomeSlider';
import Featured_Products from "../Featured_Products/Featured_Products";
import Daily_Deals from "../Daily_Deals/Daily_Deals";
import All_Products from "../All_Products/All_Products";
import SelloutCards from "../SelloutCards/SelloutCards";
import "./Home.css";
import {connect} from 'react-redux';

import {
get_home_page_main_slider_data,
get_home_page_slider1_data,
get_home_page_slider2_data,
get_home_page_slider3_data
} from '../../actions/HomeActions'


const mapDispatchToProps=(dispatch)=>{
return{
get_home_page_main_slider_data:()=>get_home_page_main_slider_data(dispatch),
get_home_page_slider1_data:()=>get_home_page_slider1_data(dispatch),
get_home_page_slider2_data:()=>get_home_page_slider2_data(dispatch),
get_home_page_slider3_data:()=>get_home_page_slider3_data(dispatch)
}
}

const mapStateToProps=(state)=>{
  return {
    name:state.layout.owner_info.name
  };
}


class Home extends Component{

 componentDidMount()
 {
  this.props.get_home_page_main_slider_data();
  this.props.get_home_page_slider1_data();
  this.props.get_home_page_slider2_data();
  this.props.get_home_page_slider3_data();
 }

 responsive={
                    0:{
                        items:1
                    },
                    500:{
                        items:2
                    },
                    800:{
                      items:3
                    },
                    1000:{
                      items:4
                    },
                    1300:{
                      items:5
                    }
                };
navText = ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'];


 settings = {
  "responsive":this.responsive,
  "navText":this.navText
 }

 render(){ 
  return (
      <>
      <HomeSlider />
      <h3 style={{"marginTop":"30px",
      "color":"#3A474E","textAlign":"center","line-height":"40px",
      "font-family": "Fresh Sans Medium,Helvetica,Arial,sans-serif",
      "font-weight":"bold"}}>Welcome To {this.props.name}</h3>
      <SelloutCards />
      <Featured_Products {...this.settings}/>
      <Daily_Deals {...this.settings}/>
      <All_Products {...this.settings}/>
      </>
  );
}
};

export default connect(mapStateToProps,mapDispatchToProps)(Home);
