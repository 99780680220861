import {
SET_MAINSLIDER_DATA,
SET_MAINSLIDER_ERROR,
SET_SLIDER1_DATA,
SET_SLIDER1_ERROR,
SET_SLIDER2_DATA,
SET_SLIDER2_ERROR,
SET_SLIDER3_DATA,
SET_SLIDER3_ERROR} from './types';
import {
	homepagemainsliderLink, 
	homepageslider1Link, 
	homepageslider2Link, 
	homepageslider3Link} from '../services/apiLink';


export const get_home_page_main_slider_data=(dispatch)=>{
	let result = fetch(homepagemainsliderLink, {
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    })
      .then((res) => {
          if (res.ok)
          {
            return res.json();
          }
          else {
            throw new Error('Something went wrong');
          }
      })
      .then(
        (result) => {
          dispatch({
                type: SET_MAINSLIDER_DATA,
                payload:result.data
              }
              )
        }
      )
      .catch(
        (error) => {
                      dispatch({
                type: SET_MAINSLIDER_ERROR,
                payload:[]
              }
              )
          }
        )
}

export const get_home_page_slider1_data=(dispatch)=>{
	let result = fetch(homepageslider1Link, {
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    })
      .then((res) => {
          if (res.ok)
          {
            return res.json();
          }
          else {
            throw new Error('Something went wrong');
          }
      })
      .then(
        (result) => {
          dispatch({
                type: SET_SLIDER1_DATA,
                payload:result.data
              }
              )
        }
      )
      .catch(
        (error) => {
                      dispatch({
                type: SET_SLIDER1_ERROR,
                payload:[]
              }
              )
          }
        )
}

export const get_home_page_slider2_data=(dispatch)=>{
	let result = fetch(homepageslider2Link, {
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    })
      .then((res) => {
          if (res.ok)
          {
            return res.json();
          }
          else {
            throw new Error('Something went wrong');
          }
      })
      .then(
        (result) => {
          dispatch({
                type: SET_SLIDER2_DATA,
                payload:result.data
              }
              )
        }
      )
      .catch(
        (error) => {
                      dispatch({
                type: SET_SLIDER2_ERROR,
                payload:[]
              }
              )
          }
        )
}

export const get_home_page_slider3_data=(dispatch)=>{
	let result = fetch(homepageslider3Link, {
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    })
      .then((res) => {
          if (res.ok)
          {
            return res.json();
          }
          else {
            throw new Error('Something went wrong');
          }
      })
      .then(
        (result) => {
          dispatch({
                type: SET_SLIDER3_DATA,
                payload:result.data
              }
              )
        }
      )
      .catch(
        (error) => {
                      dispatch({
                type: SET_SLIDER3_ERROR,
                payload:[]
              }
              )
          }
        )
}
