import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';


const FAQ=(props)=>{
	return(
		<>
		<div class="page-style-a">
	        <div class="container">
	            <div class="page-intro">
	                <h2>FAQ</h2>
	                <ul class="bread-crumb">
	                    <li class="has-separator">
	                        <i class="ion ion-md-home"></i>
	                        <Link to="/">Home</Link>
	                    </li>
	                    <li class="is-marked">
	                        <Link to="/faq">FAQ</Link>
	                    </li>
	                </ul>
	            </div>
	        </div>
    	</div>
    	<div class="page-faq u-s-p-t-80">
        <div class="container">
            <div dangerouslySetInnerHTML={ {__html: props.faq} } />
        </div>
    </div>
		</>
		)
}

const mapStateToProps=(state)=>
{
    return{
        faq:state.layout.owner_info.faq
    }
}

export default connect(mapStateToProps,null)(FAQ);
