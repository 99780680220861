import {
  FETCH_ORDER_LIST,
  ORDER_CANCEL,
  ORDER_REQUEST,
} from "../actions/types";

const initialState = {
  items: [],
  item: {},
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ORDER_LIST:
      return {
        ...state,
        items: action.payload,
        isLoading: false,
      };
    case ORDER_CANCEL:
      return {
        ...state,
      };
    case ORDER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
}
