import { SET_LAYOUT_INFO, SET_LAYOUT_ERROR, SET_OWNER_INFO, SET_OWNER_ERROR } from '../actions/types';

const initialState = {
  nav_state: 0,
  owner_state:0,
  navbar_list:[],
  owner_info: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_LAYOUT_INFO:
      let temp=[];
      action.payload.map((item,index)=>{
        if(item.id>0)
        {
          temp.push(item);
        }
      });

      return {
        ...state,
        nav_state:1,
        navbar_list:temp
      };
    case SET_LAYOUT_ERROR:
      return {
        ...state,
        nav_state:-1,
        navbar_list:[]
      };
    case SET_OWNER_INFO:
      return {
        ...state,
        owner_state:1,
        owner_info:action.payload
      };
    case SET_OWNER_ERROR:
      return {
        ...state,
        owner_state:-1,
        owner_info: {}
      };

    default:
      return state;
  }
}