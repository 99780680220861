import 
{
	SET_SIMILAR_PRODUCT_DETAIL,
	RESET_SIMILAR_PRODUCT_DETAIL, 
	SET_SIMILAR_PRODUCT_DETAIL_ERROR
} from '../actions/types';


const initialState = {
	similar_product_detail_status:0,
	similar_product_detail:[]
}


let similarProductDetailReducer =(state=initialState,action)=>{
	switch(action.type){
		case SET_SIMILAR_PRODUCT_DETAIL:
			return {similar_product_detail_status:1,similar_product_detail:action.payload}
		case RESET_SIMILAR_PRODUCT_DETAIL:
			return {similar_product_detail_status:0,similar_product_detail:[]}
		case SET_SIMILAR_PRODUCT_DETAIL_ERROR:
			return {similar_product_detail_status:-1,similar_product_detail:[]}
		default:
			return state
	}
}

export default similarProductDetailReducer;