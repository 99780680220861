import React from 'react';

import {
	SET_ALL_PRODUCT_DATA,
	RESET_ALL_PRODUCT_DATA,
	SET_ALL_PRODUCT_ERROR,
	PAGENATE_ALL_PRODUCT_DATA
} from '../actions/types';


let initialState ={
	all_product_status:0,
	all_product_data:[],
	total_item:0,
	pagenation_status:0
}

const allProductReducer = (state=initialState, action)=>{
	switch(action.type){
		case SET_ALL_PRODUCT_DATA:
			return {
				...state,
				all_product_status:1,
				all_product_data:action.payload.data,
				total_item:action.payload.totalRecords,
				pagenation_status:1
			}
		case SET_ALL_PRODUCT_ERROR:
			return {
				...state,
				all_product_status:-1,
				all_product_data:[],
				total_item:0
			}
		case RESET_ALL_PRODUCT_DATA:
			return{
				...state,
				all_product_status:0,
				pagenation_status:0
			}
		case PAGENATE_ALL_PRODUCT_DATA:
			return{
				...state,
				all_product_status:0
			}
		default:
			return state;
	}
}

export default allProductReducer;