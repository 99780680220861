import {
  PRODUCT_COMPLETE_LIST
} from '../actions/types';

import {allproductLink} from '../services/apiLink';
import store from '../store';


export const get_data=(dispatch)=>{

  let local_state={
    "start": -1,
    "limit": -1,
  }

	let result = fetch(allproductLink, {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(local_state)
    })
      .then((res) => {
          if (res.ok)
          {
            return res.json();
          }
          else {
            throw new Error('Something went wrong');
          }
      })
      .then(
        (result) => {
          dispatch({
                type: PRODUCT_COMPLETE_LIST,
                payload:result.data
              }
              )
        }
      )
      .catch(
        (error) => {
              console.log(error);
          }
        )
}

export const productcompletelist_status = ()=>{
  let products = store.getState().product_complete_list.products;
  if(products.length>0){
    return 1;
  }
  else
  {
    return 0;
  }
}


export const filtered_product_list=(params)=>{

  if(!(("sort_column" in params) && params["sort_column"]!=='' && params["sort_column"]!== undefined && params["sort_column"]!== null ))
  {
    params["sort_column"]="product_name";
  }

  if(!(("sort_order" in params) && params["sort_order"]!=='' && params["sort_order"]!== undefined  && params["sort_order"]!== null ))
  {
    params["sort_order"]="ASC";
  }

  if(!(("start" in params) && params["start"]!=='' && params["start"]!== undefined  && params["start"]!== null))
  {
    params["start"]=0;
  }

  if(!(("limit" in params) && params["limit"]!=='' && params["limit"]!== undefined  && params["limit"]!== null))
  {
    params["limit"]=10;
  }

  let products = store.getState().product_complete_list.products;
  let temp =[];

  if(("product_category_id" in params) && params["product_category_id"]!=='' && params["product_category_id"]!== undefined && params["product_category_id"]!== null)
  {
    let product_category_id = String(params["product_category_id"]).split(",");

    products.map((item,index)=>{
      if((product_category_id.indexOf(String(item["product_category_id"]))) > -1)
      {
        temp.push(item);
      }
    })
    products = temp;
    temp = [];
  }

  if(("filter_column" in params) && params["filter_column"]!==''  && params["filter_column"]!== undefined && params["filter_column"]!== null &&
      ("filter_value" in params) && params["filter_value"]!==''  && params["filter_value"]!== undefined && params["filter_value"]!== null)
  {
    products.map((item,index)=>{
      if(item[params["filter_column"]].toLowerCase()===params["filter_value"].toLowerCase())
      {
        temp.push(item);
      }
    })
    products = temp;
    temp = [];
  }


    if(("min_price" in params) && params["min_price"]!==''  && params["min_price"]!== undefined && params["min_price"]!== null &&
      ("max_price" in params) && params["max_price"]!==''  && params["max_price"]!== undefined && params["max_price"]!== null)
  {
    products.map((item,index)=>{
      if(item["price"]>=params["min_price"]&&item["price"]<=params["max_price"])
      {
        temp.push(item);
      }
    })
    products = temp;
    temp = [];
  }


    if(("search" in params) && params["search"]!=='' && params["search"]!== undefined && params["search"]!== null)
  {
    products.map((item,index)=>{
      if((String(item['product_name']).toLowerCase().indexOf(params["search"].toLowerCase()) > -1)||
         (String(item['product_category_name']).toLowerCase().indexOf(params["search"].toLowerCase()) > -1)||
         (String(item['description']).toLowerCase().indexOf(params["search"].toLowerCase()) > -1))
      {
        temp.push(item);
      }
    })
    products = temp;
    temp = [];
  }

  if(params["sort_order"].toLowerCase()==='asc')
  {
    products.sort(function(a, b){
    if(a[params["sort_column"]].toLowerCase() < b[params["sort_column"]].toLowerCase()) { return -1; }
    if(a[params["sort_column"]].toLowerCase() > b[params["sort_column"]].toLowerCase()) { return 1; }
    return 0;
  });
  }
  else
  {
    products.sort(function(a, b){
    if(a[params["sort_column"]] < b[params["sort_column"]]) { return 1; }
    if(a[params["sort_column"]] > b[params["sort_column"]]) { return -1; }
    return 0;
  });
  }


  let totalRecords = products.length;
  products = products.slice(params["start"], params["start"]+params["limit"])
  
  return {
    ...params,
    "data":products,
    "totalRecords":totalRecords,
  }
}
