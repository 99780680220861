import React,{useState} from 'react';
import { useHistory } from 'react-router-dom';

import { Input, AutoComplete } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import {Link} from 'react-router-dom';

import {get_data} from '../../actions/autoComplete';

import "./GlobalSearch.css";

const renderTitle = (title: string, query: string) => (
  <span>
    {title}
  </span>
);

const renderItem = (title: string, query: string) => ({
  value: title,
  label: (
    <Link
      to ={`/search/${title}`}
    ><div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
    
      {title}
    </div>
  </Link>
  ),
});




const GlobalSearch = (props) => {

const [options,setOptions] = useState([]);
const history = useHistory();

const search_page=(value)=>{
    setOptions([]);
    history.push(`/search/${value}`);
  }

const show_auto_complete=(value)=>
{
  let data = get_data(value);

  let output = [];
  let temp_cat = "";
  let temp = [];

  data.map((item,index)=>{
    if(item.product_category_name===temp_cat)
    {
      temp.push(renderItem(item.product_name, value))
    }
    else
    {
      if(temp_cat !=='')
      {
        output.push(
          {
            label: renderTitle(temp_cat, value),
            options: temp,
          })
      }
      
      temp_cat=item.product_category_name;
      temp=[];
      temp.push(renderItem(item.product_name))
    }
    
    })

    if(temp_cat !=='')
      {
        output.push(
          {
            label: renderTitle(temp_cat),
            options: temp,
          })
      }
      
  setOptions(output)
}

return(
  <AutoComplete
    dropdownClassName="certain-category-search-dropdown"
    dropdownMatchSelectWidth={500}
    style={{ width: "100%" }}
    options={options}
  >
    <Input.Search size="large" placeholder="Search Everything" 
    onChange={(e)=>show_auto_complete(e.target.value)}
    onSearch={(value,event)=>search_page(value)}
    enterButton/>
  </AutoComplete>
  );
}

export default GlobalSearch;