import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

const About=(props)=>
{
	return(
		<>
		<div class="page-style-a">
	        <div class="container">
	            <div class="page-intro">
	                <h2>About</h2>
	                <ul class="bread-crumb">
	                    <li class="has-separator">
	                        <i class="ion ion-md-home"></i>
	                        <Link to="/">Home</Link>
	                    </li>
	                    <li class="is-marked">
	                        <Link to="/about">About</Link>
	                    </li>
	                </ul>
	            </div>
	        </div>
    	</div>
    	<div class="page-about u-s-p-t-80">
        <div class="container">
            <div dangerouslySetInnerHTML={ {__html: props.about} } />
        </div>
    </div>
		</>
		);
}


const mapStateToProps=(state)=>{
  return {
    about:state.layout.owner_info.about
  }
}


export default connect(mapStateToProps,null)(About);