import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import React, { Component } from 'react';

class MapPage extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        stores: []
      }
    }
  
    displayMarkers = () => {
      return this.state.stores.map((store, index) => {
        return <Marker key={index} id={index} position={{
         lat: store.latitude,
         lng: store.longitude
       }}
       onClick={() => console.log("You clicked me!")} />
      })
    }
  
    render() {
      return (
          <Map
            google={this.props.google}
            zoom={15}
            style={mapStyles}
            initialCenter={{ lat: -33.954299, lng: 151.1350946}}
          >
            <Marker position={{ lat: -33.954299, lng: 151.1350946}} />
          </Map>
      );
    }
  }

  const mapStyles = {
    width: '100%',
    height: '55%',
  };
  

  export default GoogleApiWrapper({
    apiKey: 'AIzaSyAaBx__3P4UG2uCp0CY5vN6ahjOYmsxa6Q'
  })(MapPage);
  
  