import React from 'react';

import {
	PRODUCT_COMPLETE_LIST
} from '../actions/types';


let initialState ={
	products:[]
}

const ProductCompleteListReducer = (state=initialState, action)=>{
	switch(action.type){
		case PRODUCT_COMPLETE_LIST:
			return {
				products:action.payload
			}
		default:
			return state;
	}
}

export default ProductCompleteListReducer;