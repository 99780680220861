import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import {
    ADD_PRODUCT_TO_CART,
    DECREMENT_CART_ITEM_QUANTITY,
    INCREMENT_CART_ITEM_QUANTITY,
    REMOVE_PRODUCT_FROM_CART,
    REFRESH_CART
} from '../../actions/types';


const ProductCard =(props)=>
{
    const handleInput=(qty)=>
    {
        console.log(qty);
        if(/^\d+$/.test(qty))
        {
            props.addToCart({...props.data,"id":props.id },parseInt(qty));
        }
        else if(qty==='')
        {
            props.addToCart({...props.data,"id":props.id },0);
        }
    }

    const focusOut=(id)=>
    {
        props.refreshCart();
    }
    
	return(
      <div className="item" style={{"margin":"10px","padding":"auto","width":"200px","height":"320px"}}>
              <Link to={`/product/${props.name}`.toLowerCase()}>
                    <div className="image-container" style={{"width":"200px"}}>
                        <img style={{"display":"block","height":"70px","width":"100px","margin-left":"auto","margin-right":"auto", "margin-top":"20px"}} src={`${props.image}`} alt="Product" />
                    </div>
              </Link>
{/*              <div className="item-action-behaviors">
                 <Link to={`/product/${props.name}`.toLowerCase()} className="item-quick-look" data-toggle="modal" href="#quick-view" style={{"margin":"5px"}}>Quick Look
                 </Link>
                 <a className="item-addCart" href="javascript:void(0)" style={{"margin":"5px"}}>Add to Cart</a>
              </div>*/}
          <div className="item-content">
            <div className="what-product-is">
                <ul className="bread-crumb">
                    <li>
                        <Link to={`/category/${props.category}`.toLowerCase()}>{props.category}/</Link>
                    </li>
                </ul>
                <h6 className="item-title">
                    <Link to={`/product/${props.name}`.toLowerCase()}>{props.name}</Link>
                </h6>
                {/*<div className="item-stars">
                    <div className="star" title="0 out of 5 - based on 0 Reviews">
                        <span style={{"width":"0"}}></span>
                    </div>
                    <span>(0)</span>
                </div>*/}
            </div>
            <div className="price-template">
                <div className="item-new-price">
                    ${props.data.price}
                </div>
            </div>
            {
                (props.id in props.cart.cart_agg)===true?(
                            <>  
                                <span>Quantity:</span>
                                <div class="quantity">
                                    <input type="text" class="quantity-text-field" 
                                        value={props.cart.cart_agg[props.id]} 
                                        onChange={(e)=>handleInput(e.target.value)} 
                                        onBlur={()=>focusOut(props.id)}/>
                                    <a class="plus-a" onClick={()=>props.increaseInCart(props.id)}>&#43;</a>
                                    <a class="minus-a" onClick={()=>props.decreaseInCart(props.id)}>&#45;</a>
                                </div>
                                <button className="button button-outline-secondary" style={{"margin":"5px"}} type="button" onClick={()=>props.removeFromCart(props.id)}><i class="fas fa-trash-alt"></i></button>
                            </>
                    ):(<button className="button button-outline-secondary" type="button" onClick={()=>props.addToCart({...props.data,"id":props.id },1)}><i class="ion ion-md-basket"></i>&nbsp;Add to Cart</button>)
            }
        </div>
    </div>
  );

}

const mapDispatchToProps=(dispatch)=>{
    return{
        addToCart:(item,quantity)=>{
                        item['quantity']=quantity;
                        dispatch({
                            type:ADD_PRODUCT_TO_CART,
                            payload:item
                        })
                    },
        removeFromCart:(id)=>{
                        dispatch({
                            type:REMOVE_PRODUCT_FROM_CART,
                            payload:id
                        })
                    },
        increaseInCart:(id)=>{
                        dispatch({
                            type:INCREMENT_CART_ITEM_QUANTITY,
                            payload:id
                        })
                    },
        decreaseInCart:(id)=>{
                        dispatch({
                            type:DECREMENT_CART_ITEM_QUANTITY,
                            payload:id
                        })
                    },
        refreshCart:()=>{dispatch({
                        type:REFRESH_CART
                    })}
    }
}


const mapStateToProps=(state)=>{
    return{
        cart:state.cart
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ProductCard);