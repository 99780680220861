
import {allproductLink} from '../services/apiLink';
import {productcompletelist_status,filtered_product_list} from './productCompleteListAction';


export const get_data=(query)=>{
if (query==="" || query===undefined || query===null)
	{
		return []
	}

let local_state={
	"search":query,
	"sort_column":"product_category_name",
	"sort_order":"ASC",
	"start":0,
	"limit":4
}
   
try{

      if(productcompletelist_status()===1)
      {
        let result = filtered_product_list(local_state);
        return result.data;
      }
      else
      {
      	return []
      }

    }
catch(err)
    {
    	return [];
    }
  }
