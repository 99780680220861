import React from "react";
import moment from 'moment';
import openNotificationWithIcon from './../actions/openNotificationWithIcon';

import {
    ADD_PRODUCT_TO_CART,
    DECREMENT_CART_ITEM_QUANTITY,
    INCREMENT_CART_ITEM_QUANTITY,
    REMOVE_PRODUCT_FROM_CART,
    REFRESH_CART,
    RESET_CART,
    BULK_UPDATE_CART,
    SET_PLACE_ORDER_LOADING,
    RESET_PLACE_ORDER_LOADING,
    SET_ORDER_NOTE,
    SET_PREFERRED_DATE
} from '../actions/types';

const initialState={
    cart:[],
    cart_agg:{"-1":0},
    loading:0,
    note:"",
    preferred_date:""
}

export default function(state = initialState, action) {
    let updatedCart;
    let updatedItemIndex;
    let updatedCartAgg;

    switch(action.type){
        case ADD_PRODUCT_TO_CART:
            updatedCart = [...state.cart];
            updatedCartAgg = state.cart_agg;

            updatedItemIndex = updatedCart.findIndex(item => item.id === action.payload.id);

            if(updatedItemIndex < 0) {
                updatedCart.push(action.payload);
                updatedCartAgg[action.payload.id]=action.payload.quantity;
            } else {
                updatedCart[updatedItemIndex] = action.payload;
                updatedCartAgg[action.payload.id] = action.payload.quantity;
            }

            const type = "success";
            const message = "Success";
            const description = String(action.payload.product_name)+" added to Cart";
            openNotificationWithIcon(type, message, description);

            return {...state,cart: updatedCart, cart_agg:updatedCartAgg};

        case REMOVE_PRODUCT_FROM_CART:
            updatedCart = [...state.cart];
            updatedCartAgg = state.cart_agg;

            updatedItemIndex = updatedCart.findIndex(
                item => item.id === action.payload
            );

            if(updatedItemIndex>=0)
            {

                updatedCart.splice(updatedItemIndex, 1);
                delete updatedCartAgg[action.payload];
                const type = "success";
                const message = "Success";
                const description = String(state.cart[updatedItemIndex].product_name)+" removed from Cart";
                openNotificationWithIcon(type, message, description);
            }

            return {...state,cart: updatedCart, cart_agg:updatedCartAgg};

        case INCREMENT_CART_ITEM_QUANTITY:
            updatedCart = [...state.cart];
            updatedCartAgg = state.cart_agg;

            updatedItemIndex = updatedCart.findIndex(
                item => item.id === action.payload
            );

            if(updatedItemIndex>=0)
            {
                const incrementedItem = {
                ...updatedCart[updatedItemIndex]
                };
                incrementedItem.quantity++;
                updatedCart[updatedItemIndex] = incrementedItem;
                updatedCartAgg[action.payload] = incrementedItem.quantity;
            }
            return {...state,cart: updatedCart, cart_agg:updatedCartAgg};

        case DECREMENT_CART_ITEM_QUANTITY:
            updatedCart = [...state.cart];
            updatedCartAgg = state.cart_agg;

            updatedItemIndex = updatedCart.findIndex(
                item => item.id === action.payload
            );

            if(updatedItemIndex>=0)
            {
                const incrementedItem = {
                ...updatedCart[updatedItemIndex]
                };
                incrementedItem.quantity--;

                updatedCart[updatedItemIndex] = incrementedItem;
                updatedCartAgg[action.payload] = incrementedItem.quantity;

                if(incrementedItem.quantity<=0)
                {
                    updatedCart.splice(updatedItemIndex, 1);
                    delete updatedCartAgg[action.payload];
                }
            }
            return {...state,cart: updatedCart, cart_agg:updatedCartAgg};

        case REFRESH_CART:
            updatedCart = [];
            updatedCartAgg = state.cart_agg;

            state.cart.map((item,index)=>{
                if(item.quantity<=0)
                {
                    delete updatedCartAgg[item.id];
                }
                else{
                    updatedCart.push(item);
                }
            })
            return {...state,cart: updatedCart, cart_agg:updatedCartAgg};

        case RESET_CART:
            return {cart:[],cart_agg:{"-1":0},loading:0,note:"",
                    preferred_date:moment().format('YYYY-MM-DD')}

        case BULK_UPDATE_CART:
            updatedCart=[];
            updatedCartAgg = {"-1":0}
            action.payload.map((item,index)=>{
                updatedCart.push(item);
                updatedCartAgg[item["product_id"]]=item["quantity"]
            });
            return {...state,cart:updatedCart,cart_agg:updatedCartAgg,loading:0}

        case SET_PLACE_ORDER_LOADING:
            return {...state,loading:1}

        case RESET_PLACE_ORDER_LOADING:
            return {...state,loading:0}

        case SET_ORDER_NOTE:
            return {...state,note:action.payload}

        case SET_PREFERRED_DATE:
            return {...state,preferred_date:action.payload}

        default:
            return state;
    }
}