import 
{
	SET_PRODUCT_DETAIL,
	RESET_PRODUCT_DETAIL, 
	SET_PRODUCT_DETAIL_ERROR
} from '../actions/types';


const initialState = {
	product_detail_status:0,
	product_detail: {}
}


let ProductDetailReducer =(state=initialState,action)=>{
	switch(action.type){
		case SET_PRODUCT_DETAIL:
			return {product_detail_status:1,product_detail:action.payload}
		case RESET_PRODUCT_DETAIL:
			return {product_detail_status:0,product_detail:{}}
		case SET_PRODUCT_DETAIL_ERROR:
			return {product_detail_status:-1,product_detail:{}}
		default:
			return state
	}
}

export default ProductDetailReducer;