import React, { Component } from "react";
import { logout, isLogin, passwordForgot } from "../../actions/AuthActions";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button, notification, Spin } from "antd";

const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
  });
};
class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      loading: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { email } = this.state;
    if (email !== "") {
      //this.toggleLogin(true);
      this.props.passwordForgot({
        email: this.state.email,
        onSuccess: ({ msg }) => {
          this.setState({
            loading: false,
          });
          const type = "success";
          const message = "Success";
          const description = msg;
          openNotificationWithIcon(type, message, description);
          //alert(msg);
        },
        onFailure: (errorMsg) => {
          //Alert error message
          this.setState({
            loading: false,
          });
          const type = "error";
          const message = "error";
          const description = errorMsg;
          openNotificationWithIcon(type, message, description);
          // alert(errorMsg);
        },
      });
    } else {
      alert("Email is Required");
    }
  };

  inputForgotPasswordChangeHandler = (event) => {
    this.setState({ email: event.target.value });
  };

  render() {
    console.log("state", this.props.loading);
    return (
      <>
        <div class="page-style-a">
          <div class="container">
            <div class="page-intro">
              <h2>Lost Password</h2>
              <ul class="bread-crumb">
                <li class="has-separator">
                  <i class="ion ion-md-home"></i>
                  <Link to="/">
                    <i class=""></i> Home
                  </Link>
                  {/* <a href="home.html">Home</a> */}
                </li>
                <li class="is-marked">
                  <a href="#">Lost Password</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="page-lost-password u-s-p-t-80">
          <div class="container">
            <div class="page-lostpassword">
              <h2 class="account-h2 u-s-m-b-20">Forgot Password ?</h2>
              <h6 class="account-h6 u-s-m-b-30">
                Enter your email below and we will send you a link to reset your
                password.
              </h6>
              <form onSubmit={this.handleSubmit}>
                <Spin spinning={this.props.loading}>
                  <div class="w-50">
                    <div class="u-s-m-b-13">
                      <label for="user-name-email">
                        Email
                        <span class="astk">*</span>
                      </label>
                      <input
                        type="text"
                        id="email"
                        class="text-field"
                        placeholder="Email"
                        required="required"
                        value={this.state.value}
                        onChange={this.inputForgotPasswordChangeHandler}
                      />
                    </div>
                    <div class="u-s-m-b-13">
                      <button class="button button-outline-secondary">
                        Get Reset Link
                      </button>
                    </div>
                  </div>
                </Spin>
              </form>
              <div class="page-anchor">
                <Link to="/sign-in">
                  <i class="fas fa-long-arrow-alt-left u-s-m-r-9"></i>Back to
                  Login
                </Link>
                {/* <a href="account.html">
                    <i class="fas fa-long-arrow-alt-left u-s-m-r-9"></i>Back to
                    Login
                  </a> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.auth.isLoading,
});

export default connect(mapStateToProps, { passwordForgot })(ForgotPassword);
