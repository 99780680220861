import React, { Component } from "react";
import { logout, updateUser, fetchProfile } from "../../actions/AuthActions";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { notification } from "antd";

const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
  });
};

class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      state: "",
      post_code: "",
      fetchProfile: false,
    };
  }

  componentDidMount() {
    const { fetchProfile } = this.props;
    fetchProfile();
    let userDetails = JSON.parse(localStorage.getItem("profileData"));
    this.setState({
      name: userDetails.name,
      email: userDetails.email,
      phone: userDetails.mobile,
      address: userDetails.address,
      city: userDetails.city,
      state: userDetails.state,
      post_code: userDetails.post_code,
      organization: userDetails.organization,
      abn: userDetails.abn,
    });
  }

  componentDidUpdate(prevProps) {
    const { fetchProfile } = this.props;
    if (prevProps.fetchProfile !== fetchProfile) {
      this.setState({ fetchProfile });
    }
  }

  inputEmailChangeHandler = (event) => {
    this.setState({ email: event.target.value });
  };
  inputPasswordChangeHandler = (event) => {
    this.setState({ password: event.target.value });
  };

  inputOrganizationChangeHandler = (event) => {
    this.setState({ organization: event.target.value });
  };

  inputAttentionChangeHandler = (event) => {
    this.setState({ name: event.target.value });
  };

  inputPhoneChangeHandler = (event) => {
    this.setState({ phone: event.target.value });
  };

  inputAddressChangeHandler = (event) => {
    this.setState({ address: event.target.value });
  };

  inputCityChangeHandler = (event) => {
    this.setState({ city: event.target.value });
  };

  inputStateChangeHandler = (event) => {
    this.setState({ state: event.target.value });
  };

  inputPostalCodeChangeHandler = (event) => {
    this.setState({ postal_code: event.target.value });
  };

  inputConfirmPasswordChangeHandler = (event) => {
    this.setState({ confirm_password: event.target.value });
  };

  inputAbnChangeHandler = (event) => {
    this.setState({ abn: event.target.value });
  };

  toggleLogin(value) {
    this.setState({
      loading: value,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, phone, address, organization, abn } = this.state;
    //console.log(this.state, "state");
    if (name !== "") {
      if (phone !== "") {
        if (address != "") {
          if (organization != "") {
            if (abn != "") {
              const value = {
                full_name: this.state.name,
                mobile: this.state.phone,
                address: this.state.address,
                city: this.state.city,
                state: this.state.state,
                post_code: this.state.post_code,
                organization: this.state.organization,
                abn: this.state.abn,
              };
              console.log(value);
              this.toggleLogin(true);

              this.props.updateUser({
                accessToken: window.localStorage.getItem("token"),
                updateDetails: value,

                onSuccess: () => {
                  this.toggleLogin(false);
                  const type = "success";
                  const message = "Success";
                  const description = "Profile Updated Successfully";
                  openNotificationWithIcon(type, message, description);
                  //alert("Profile Updated Successfully");
                },
                onFailure: (errorMsg) => {
                  this.toggleLogin(false);
                  const type = "error";
                  const message = "Error";
                  const description = errorMsg;
                  openNotificationWithIcon(type, message, description);
                  //alert(errorMsg);

                  //Alert error message
                },
              });
            } else {
              alert("ABN is required");
            }
          } else {
            alert("Organization or Bussiness Name is required");
          }
        } else {
          alert("Address is required");
        }
      } else {
        alert("Phone is required");
      }
    } else {
      alert("Name is Required");
    }
  };

  render() {
    const { profile_data } = this.props;
    return (
      <>
        <div className="container">
          <div className="row">
            {/* <div className="col-md-3 ">
              <div className="list-group ">
                <a
                  href="#"
                  className="list-group-item list-group-item-action active"
                >
                  Profile
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  User Management
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  Used
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  Enquiry
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  Dealer
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  Media
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  Post
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  Category
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  New
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  Comments
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  Appearance
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  Reports
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  Settings
                </a>
              </div>
            </div> */}
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <h4>My Profile</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8">
                      <form onSubmit={this.handleSubmit}>
                        <div className="form-group row">
                          <label
                            htmlFor="username"
                            className="col-4 col-form-label"
                          >
                            Organization/Bussiness Name*
                          </label>
                          <div className="col-8">
                            <input
                              id="username"
                              name="organization"
                              placeholder="Organization"
                              className="form-control here"
                              required="required"
                              defaultValue={
                                (profile_data && profile_data.organization) ||
                                ""
                              }
                              onChange={this.inputOrganizationChangeHandler}
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="name"
                            className="col-4 col-form-label"
                          >
                            ABN*
                          </label>
                          <div className="col-8">
                            <input
                              id="name"
                              name="abn"
                              placeholder="ABN"
                              className="form-control here"
                              defaultValue={
                                (profile_data && profile_data.abn) || ""
                              }
                              onChange={this.inputAbnChangeHandler}
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="lastname"
                            className="col-4 col-form-label"
                          >
                            Attention*
                          </label>
                          <div className="col-8">
                            <input
                              id="lastname"
                              name="attention"
                              placeholder="Attention"
                              className="form-control here"
                              defaultValue={
                                (profile_data && profile_data.name) || ""
                              }
                              onChange={this.inputAttentionChangeHandler}
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="text"
                            className="col-4 col-form-label"
                          >
                            Email*
                          </label>
                          <div className="col-8">
                            <input
                              id="text"
                              name="email"
                              placeholder="Email"
                              className="form-control here"
                              required="required"
                              disabled="disabled"
                              defaultValue={
                                (profile_data && profile_data.email) || ""
                              }
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="Phone"
                            className="col-4 col-form-label"
                          >
                            Phone*
                          </label>
                          <div className="col-8">
                            <input
                              id="Phone"
                              name="phone"
                              placeholder="Phone"
                              className="form-control here"
                              required="required"
                              defaultValue={
                                (profile_data && profile_data.mobile) || ""
                              }
                              onChange={this.inputPhoneChangeHandler}
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="Address"
                            className="col-4 col-form-label"
                          >
                            Address*
                          </label>
                          <div className="col-8">
                            <input
                              id="Address"
                              name="address"
                              placeholder="Address"
                              className="form-control here"
                              defaultValue={
                                (profile_data && profile_data.address) || ""
                              }
                              onChange={this.inputAddressChangeHandler}
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="city"
                            className="col-4 col-form-label"
                          >
                            City*
                          </label>
                          <div className="col-8">
                            <input
                              id="city"
                              name="city"
                              placeholder="City"
                              className="form-control here"
                              defaultValue={
                                (profile_data && profile_data.city) || ""
                              }
                              onChange={this.inputCityChangeHandler}
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="state"
                            className="col-4 col-form-label"
                          >
                            State
                          </label>
                          <div className="col-8">
                            <input
                              id="state"
                              name="state"
                              placeholder="State"
                              className="form-control here"
                              defaultValue={
                                (profile_data && profile_data.state) || ""
                              }
                              onChange={this.inputStateChangeHandler}
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="postal_code"
                            className="col-4 col-form-label"
                          >
                            Postal Code*
                          </label>
                          <div className="col-8">
                            <input
                              id="postal_code"
                              name="post_code"
                              placeholder="Postal Code"
                              className="form-control here"
                              defaultValue={
                                (profile_data && profile_data.post_code) || ""
                              }
                              onChange={this.inputPostalCodeChangeHandler}
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="offset-4 col-8">
                            <button
                              name="submit"
                              type="submit"
                              className="btn btn-primary"
                            >
                              Update My Profile
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
UserProfile.propTypes = {
  UserProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile_data: state.auth.profile_data,
  userData: state.auth.user,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        updateUser,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, { fetchProfile, updateUser })(
  UserProfile
);
