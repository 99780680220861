import 
{
  SET_SIMILAR_PRODUCT_DETAIL,
  RESET_SIMILAR_PRODUCT_DETAIL, 
  SET_SIMILAR_PRODUCT_DETAIL_ERROR
} from './types';

import {allproductLink} from '../services/apiLink';
import {productcompletelist_status,filtered_product_list} from './productCompleteListAction';

export const get_similar_product_data=(dispatch,id)=>
{
  dispatch({type:RESET_SIMILAR_PRODUCT_DETAIL})
  let local_state = {
                            product_category_id: id,
                            start:0,
                            limit:10
              }

  try{

      if(productcompletelist_status()===1)
      {
        let result = filtered_product_list(local_state);
        dispatch({
                    type: SET_SIMILAR_PRODUCT_DETAIL,
                    payload:result.data
                  }
                  )
        return;
      }

    }
    catch(err)
    {

    }

  let result = fetch(allproductLink, {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(local_state)
    })
      .then((res) => {
          if (res.ok)
          {
            return res.json();
          }
          else {
            throw new Error('Something went wrong');
          }
      })
      .then(
        (result) => {
        	if(result.data.length>0){
        		dispatch({
                type: SET_SIMILAR_PRODUCT_DETAIL,
                payload:result.data
              }
              );
        	}
        	else{
        		dispatch({
                type: SET_SIMILAR_PRODUCT_DETAIL_ERROR
              }
              )
        	}
          
        }
      )
      .catch(
        (error) => {
              dispatch({
                type: SET_SIMILAR_PRODUCT_DETAIL_ERROR
              }
              )
          }
        )
}

