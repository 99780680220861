import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import {trackMyOrder} from '../../actions/trackMyOrder';
import {connect} from "react-redux";
import {DEFAULT_TRACK_MY_ORDER} from '../../actions/types';
import { Button, Table, Divider, Tag, Modal, Spin, List, Avatar } from "antd";
import "antd/dist/antd.css";

import OrderListView from "../../components/Order/OrderListview";


const TrackOrder=(props)=>
{
	const [order_key,set_order_key] = useState('');
	const [email,set_email] = useState('');
	const [show_modal,set_show_modal]=useState(false);
	const { Column, ColumnGroup } = Table;

	useEffect(() => {
		props.default_track_my_order()
	},[])
	return(
		<>
		<div class="page-style-a">
	        <div class="container">
	            <div class="page-intro">
	                <h2>Track Order</h2>
	                <ul class="bread-crumb">
	                    <li class="has-separator">
	                        <i class="ion ion-md-home"></i>
	                        <Link to="/">Home</Link>
	                    </li>
	                    <li class="is-marked">
	                        <Link to="/track-order">Track Order</Link>
	                    </li>
	                </ul>
	            </div>
	        </div>
    	</div>
    	 <div class="page-track-order u-s-p-t-80">
        <div class="container">
            <div class="track-order-wrapper">
                <h2 class="track-order-h2 u-s-m-b-20 text-center">Track Your Order</h2>
                <h6 class="track-order-h6 u-s-m-b-30">To track your order please enter your Order ID in the box below and press the "Track" button. This was given to you in the confirmation email you should have received.</h6>
                <form>
                    <div class="u-s-m-b-30">
                        <label for="order-id">Order Key
                            <span class="astk">*</span>
                        </label>
                        <input required type="text" id="order-id" class="text-field" value={order_key} onChange={(event)=>set_order_key(event.target.value)}/>
                    </div><div class="u-s-m-b-30">
                        <label for="email">Email
                            <span class="astk">*</span>
                        </label>
                        <input required type="email" id="email" class="text-field" value={email} onChange={(event)=>set_email(event.target.value)}/>
                    </div>
                    <div class="u-s-m-b-30">
                        <button class="button button-outline-secondary w-100" onClick={(event)=>{event.preventDefault(); props.get_track_my_order(order_key,email);}}>TRACK</button>
                        </div>
                    <div style={{"margin-bottom":"20px","display":props.track_my_order.order_status>0?'block':'none'}}>
                    {
                    	props.track_my_order.order_status===1?(<Spin
							              size="large"
							              tip="Loading...Please Wait."
							            />):(<></>)
					}
					{
						props.track_my_order.order_status===2 && props.track_my_order.order_data.length===0?(
						<h5>No Such Order Found!!!!</h5>
						):(<></>)
                    }
                    {
						props.track_my_order.order_status===2 && props.track_my_order.order_data.length>0?(
						<Table dataSource={props.track_my_order.order_data}>
				            <Column
				              title="Order Date"
				              dataIndex="order_date"
				              key="order_date"
				            />
				            <Column title="Status" dataIndex="status" key="status" />
				            <Column
				              title="Action"
				              key="id"
				              render={(text, record) => (<><Button
						                    type="primary"
						                    style={{ background: "#1a004a", borderColor: "yellow" }}
						                    onClick={()=>set_show_modal(true)}
						                  >
						                    View Product
						                  </Button>
						                  <Modal
						                    title="Product List"
						                    visible={show_modal}
						                    onOk={()=>set_show_modal(false)}
                    						onCancel={()=>set_show_modal(false)}
						                  >
						                    <OrderListView dataFromParent={{orderDetails:record.product_details}} />
						                  </Modal>
						                  </>
						                  )
						              } />

				        </Table>
						):(<></>)
                    }
                    <button type="submit" class="button button-outline-secondary w-100" onClick={(event)=>{event.preventDefault(); props.default_track_my_order();set_order_key(''); set_email('')}}>CLEAR</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
		</>
		);
}

const mapStateToProps=(state)=>{
	return{
		track_my_order:state.track_my_order
	}
}

const mapDispatchToProps=(dispatch)=>{
	return{
		get_track_my_order:(order_key,email)=>{if(order_key && email){trackMyOrder(dispatch,order_key,email)}},
		default_track_my_order:()=>{dispatch({
			type:DEFAULT_TRACK_MY_ORDER
		})}
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(TrackOrder);