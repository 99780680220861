import React, { Component } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Spin, Alert, Space } from "antd";
import "antd/dist/antd.css";
import { getnavinfo, getownerinfo } from "../actions/layoutActions";
import "./App.css";
import Login from "./Auth/Login";
import Logout from "./Auth/Logout";
import MyOrder from "./Order/MyOrder";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import MainLayout from "./MainLayout/MainLayout";
import Home from "./Home/Home";
import Category from "./Category/Category";
import Product from "./Product/Product";
import All_Product_List from "./All_Product_List/All_Product_List";
import DailyDealsPage from "./DailyDealsPage/DailyDealsPage";
import FeaturedProductsPage from "./FeaturedProductsPage/FeaturedProductsPage";
import SearchTermPage from "./SearchTermPage/SearchTermPage";
import Contact from "./Contact/Contact";
import ForgotPassword from "./Auth/ForgotPassword";
import Cart from "./Cart/Cart";
import Checkout from "./Checkout/Checkout";
import UserProfile from "../Screens/Profile/UserProfile";
import FAQ from '../Screens/FAQ/FAQ';
import TrackOrder from '../Screens/TrackOrder/TrackOrder';
import PrivacyPolicy from '../Screens/PrivacyPolicy/PrivacyPolicy';
import About from '../Screens/About/About';
import LoginPage from '../Screens/Login/LoginPage';
import SignUpPage from '../Screens/SignUp/SignUpPage';
import MapPage from '../components/Map/MapPage'

const mapDispatchToProps = (dispatch) => {
  return {
    get_nav_info: () => {
      getnavinfo(dispatch);
    },
    get_owner_info: () => {
      getownerinfo(dispatch);
    }
  };
};

const mapStateToProps = (state) => {
  return {
    layout_nav_info: state.layout,
    layout_owner_info: state.layout,
  };
};

class App extends Component {
  componentDidMount() {
    this.props.get_owner_info();
    this.props.get_nav_info();
  }
  render() {
    document.title =
      typeof this.props.layout_owner_info.owner_info.webpage_title !=
      "undefined"
        ? this.props.layout_owner_info.owner_info.webpage_title
        : "Loading...";
    document.getElementById("favicon").href =
      typeof this.props.layout_owner_info.owner_info.favicon != "undefined"
        ? this.props.layout_owner_info.owner_info.favicon
        : " ";

   return (
        <div className="App">
          <MainLayout>
            <Switch>
              // public routes
              <Route path={"/"} exact component={Home} />
              <Route path={"/sign-in"} component={LoginPage} />
              <Route path={"/sign-up"} component={SignUpPage} />
              <Route path={"/login"} component={Login} />
              <Route path={"/category/:name+"} component={Category} />
              <Route path={"/product/:name+"} component={Product} />
              <PrivateRoute path={"/logout"} component={Logout} exact />
              <PrivateRoute path={"/myorder"} component={MyOrder} exact />
              <Route path={"/all-products"} component={All_Product_List} />
              <Route
                path={"/featured-products"}
                component={FeaturedProductsPage}
              />
              <Route path={"/daily-deals"} component={DailyDealsPage} />
              <Route path={"/search/:query+"} component={SearchTermPage} />
              <Route path={"/forgot-password"} component={ForgotPassword} />
              <Route path={"/my-cart"} component={Cart} />
              <Route path={"/faq"} component={FAQ} />
              <Route path={"/track-order"} component={TrackOrder} />
              <Route path={"/privacy-policy"} component={PrivacyPolicy} />
              <Route path={"/about"} component={About} />
              <Route path={"/contact-us"} component={Contact} />
              <Route path={"/map"} component={MapPage} />
              <PrivateRoute path={"/user-profile"} component={UserProfile} exact />
              // private routes // default route
              <PrivateRoute path={"/checkout"} component={Checkout} />

              <Redirect to={"/"} />
            </Switch>
          </MainLayout>
        </div>
      );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
