import {
  SET_ALL_PRODUCT_DATA,
  RESET_ALL_PRODUCT_DATA,
  SET_ALL_PRODUCT_ERROR,
  PAGENATE_ALL_PRODUCT_DATA
} from '../actions/types';

import {allproductLink} from '../services/apiLink';
import {productcompletelist_status,filtered_product_list} from './productCompleteListAction';


export const get_data=(dispatch,local_state, pagenation_reset)=>{
  if(pagenation_reset===1)
  {
      dispatch({
                type: RESET_ALL_PRODUCT_DATA,
                payload:[]
              });
  }
  else{
    dispatch({
                type: PAGENATE_ALL_PRODUCT_DATA,
                payload:[]
              });
  }
  if(local_state.product_category_id.length>0)
  {
    let product_category_id = local_state.product_category_id.map(a => a.id);
    local_state.product_category_id = product_category_id.join(',');
  }
  else{
    local_state.product_category_id = "";
  }


    try{

      if(productcompletelist_status()===1)
      {
        let result = filtered_product_list(local_state);
        dispatch({
                    type: SET_ALL_PRODUCT_DATA,
                    payload:{data:result.data,totalRecords:result.totalRecords}
                  }
                  )
        return;
      }

    }
    catch(err)
    {

    }


 
    let result = fetch(allproductLink, {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(local_state)
    })
      .then((res) => {
          if (res.ok)
          {
            return res.json();
          }
          else {
            throw new Error('Something went wrong');
          }
      })
      .then(
        (result) => {
          dispatch({
                type: SET_ALL_PRODUCT_DATA,
                payload:{data:result.data,totalRecords:result.totalRecords}
              }
              )
        }
      )
      .catch(
        (error) => {
                      dispatch({
                type: SET_ALL_PRODUCT_ERROR,
                payload:[]
              }
              )
          }
        )
  }
	
